import useQueryParam from '../../utils/use-query-param'
import {useConfig} from '../config-context/config-context'
import {useEffect} from 'react'

export function useGetExternalUserId() {
  const config = useConfig()
  const [externalUserId] = useQueryParam('userId')
  useEffect(() => {
    if (config) {
      if (config.useExternalUserId && !externalUserId) {
        throw new Error('Query parameter userId is required')
      }
    }
  }, [config, externalUserId])

  return externalUserId
}
