import React, {useEffect} from 'react'
import {styled, useTheme} from '../../context/theme-context/theme-context'
import ClipLoader from 'react-spinners/ClipLoader'
import {Checkbox} from '../../components/checkbox'
import {
  Error,
  Form,
  FormGroup,
  Input,
  PasswordInput,
} from '../../components/form'
import {useCreateAccountForm} from './use-create-account-form'
import {Button} from '../../components/button'
import {Spacer} from '../../components/spacer'
import {PageWrapper, Wrapper, TextWrapper} from '../../components/page-wrapper'
import {useConfig} from '../../context/config-context/config-context'
import {useToast} from '../../utils/use-toast'
import {
  useAnalyticsCurrentScreen,
  useAnalyticsLogEvent,
} from '../../utils/use-analytics'
import {useTranslations} from '../../api/onboarding'

const CreateAccount = () => {
  useAnalyticsCurrentScreen('create-external-account')
  const {translations} = useTranslations()
  const logEvent = useAnalyticsLogEvent()
  const theme = useTheme()
  const config = useConfig()

  const {ToastContainer, showToast} = useToast()

  const {
    handleEmailChange,
    handlePasswordChange,
    handleConfirmPasswordChange,
    email,
    password,
    confirmPassword,
    setIsTermsAccepted,
    isTermsAccepted,
    isLoading,
    isSuccess,
    error,
    isSubmitted,
    handleSubmit,
    onCreateAccountSuccess,
  } = useCreateAccountForm()

  useEffect(() => {
    if (isSuccess) {
      showToast(translations!['signup-account-successfully-created'])
      logEvent('accept-terms-and-link-account', {email: email.text})
      setTimeout(() => onCreateAccountSuccess(), 3000)
    }
  }, [isSuccess])

  return (
    <PageWrapper
      backgroundImage={theme.createAccount?.backgroundImage!}
      background={theme.createAccount?.backgroundColor!}
    >
      <ToastContainer variant="success" />
      <Wrapper>
        <TextWrapper
          titleColor={theme.createAccount?.titleColor}
          subtitleColor={theme.createAccount?.subtitleColor}
        >
          <h1>{translations!['signup-header']}</h1>
          <p>{translations!['signup-text']}</p>
        </TextWrapper>
        <Form onSubmit={handleSubmit}>
          {error && (
            <Error
              color={theme.createAccount?.errorMessageColor}
              variant="formError"
            >
              {translations!.firebase[(error as any).code?.replace("auth/", "")] || (error as any).message}
            </Error>
          )}
          <FormGroup>
            <Input
              color={theme.createAccount?.inputTextColor}
              background={theme.createAccount?.inputBackgroundColor!}
              type="email"
              name="email"
              value={email.text}
              onChange={e => handleEmailChange(e.target.value)}
              id="email"
              placeholder={translations!['signup-placeholder-email']}
            />
            {email.error && isSubmitted && (
              <Error color={theme.createAccount?.errorMessageColor}>
                {email.error}
              </Error>
            )}
          </FormGroup>
          <FormGroup>
            <PasswordInput
              passwordIconColor={theme.createAccount?.showPasswordIcon}
              color={theme.createAccount?.inputTextColor}
              background={theme.createAccount?.inputBackgroundColor}
              placeholder={translations!['signup-placeholder-password']}
              onChange={e => handlePasswordChange(e.target.value)}
              value={password.text}
            />
            {password.error && isSubmitted && (
              <Error color={theme.createAccount?.errorMessageColor}>
                {password.error}
              </Error>
            )}
          </FormGroup>
          <FormGroup>
            <PasswordInput
              passwordIconColor={theme.createAccount?.showPasswordIcon}
              color={theme.createAccount?.inputTextColor}
              background={theme.createAccount?.inputBackgroundColor}
              placeholder={translations!['signup-placeholder-password-confirm']}
              onChange={e => handleConfirmPasswordChange(e.target.value)}
              value={confirmPassword.text}
            />
            {confirmPassword.error && isSubmitted && (
              <Error color={theme.createAccount?.errorMessageColor}>
                {confirmPassword.error}
              </Error>
            )}
          </FormGroup>
          <Spacer size={10} />
          <FormGroup>
            <div>
              <Checkbox
                color={theme.createAccount?.acceptTermsCheckboxColor}
                onChange={() => setIsTermsAccepted(!isTermsAccepted)}
                checked={isTermsAccepted}
                aria-label="Accept terms"
              />
              <Spacer size={15} />
              <Link
                color={theme.createAccount?.acceptTermsLinkColor}
                target="_blank"
                href={config.acceptTermsLink}
              >
                {' '}
                {translations!['signup-user-terms-link-text']}
              </Link>
            </div>
            {!isTermsAccepted && isSubmitted && (
              <Error color={theme.createAccount?.errorMessageColor}>
                {translations!['user-terms-must-be-accepted']}
              </Error>
            )}
          </FormGroup>
          <Button
            disabled={isLoading || isSuccess}
            color={theme.defaultButton.textColor}
            radius={theme.defaultButton.borderRadius}
            background={theme.defaultButton.backgroundColor}
            type="submit"
          >
            {isLoading ? (
              <ClipLoader
                size={25}
                color={theme.defaultButton.textColor}
              />
            ) : (
              translations!['signup-button']
            )}
          </Button>
        </Form>
      </Wrapper>
    </PageWrapper>
  )
}

const Link = styled.a`
  color: ${p => p.theme.createAccount?.acceptTermsLinkColor};
  font-weight: 700;
  font-size: 14px;
  text-decoration: underline;
  text-underline-offset: 2px;
  &:hover {
    color: white;
  }
  &:visited {
    color: white;
  }
`

export {CreateAccount}
