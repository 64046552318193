import {useEffect} from 'react'

export function usePreloadImage(image: string | undefined) {
  useEffect(() => {
    if (image) {
      preloadImages([image])
    }
  }, [image])
}

async function preloadImages(array: string[]) {
  // @ts-ignore
  if (!preloadImages?.list) {
    // @ts-ignore
    preloadImages.list = []
  }
  // @ts-ignore
  const list = preloadImages.list
  for (let i = 0; i < array.length; i++) {
    const img = new Image()
    img.onload = function () {
      const index = list.indexOf(this)
      if (index !== -1) {
        // remove image from the array once it's loaded
        // for memory consumption reasons
        list.splice(index, 1)
      }
    }
    list.push(img)
    img.src = array[i]
  }
}
