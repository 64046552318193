import {useAuthClient} from '../api-client'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {ConsumptionProfileView} from './types/consumption-profile-view'
import {useErrorHandler} from 'react-error-boundary'
import {useConfig} from '../../context/config-context/config-context'
import {ConsumptionProfile} from './types/consumption-profile'
import {ExternalAuth, useAuth} from '../../context/auth-context/auth-context'

export const useConsumptionProfileViews = (enabled = true) => {
  const client = useAuthClient()
  const config = useConfig()
  const result = useQuery<{views: ConsumptionProfileView[]}>(
    'views/consumption-profile',
    () =>
      client('api/dynamic-views/consumption-profile', {
        queryParams: {
          ...(config.language && {language: config.language}),
        },
      }),
    {
      staleTime: Infinity,
      enabled,
    },
  )

  useErrorHandler(result.error)
  return {
    ...result,
    views: result?.data?.views,
  }
}

export const usePrefetchConsumptionProfile = (enabled = true) => {
  useConsumptionProfile(enabled)
  useConsumptionProfileViews(enabled)
}

export const useConsumptionProfile = (enabled = true) => {
  const client = useAuthClient()
  const config = useConfig()
  const auth = useAuth()
  const endPoint = config.useExternalUserId
    ? `api/users/${
        (auth as ExternalAuth)?.externalUserId
      }/footprints/consumption`
    : `_ah/api/deeds/v2/footprints/consumption`
  const result = useQuery<ConsumptionProfile>(
    'footprints/consumption',
    () => client(endPoint),
    {
      staleTime: Infinity,
      enabled,
    },
  )

  useErrorHandler(result.error)
  return {
    ...result,
    profile: result?.data?.profile,
  }
}

export const usePostConsumptionProfile = () => {
  const queryClient = useQueryClient()
  const client = useAuthClient()
  const config = useConfig()
  const auth = useAuth()
  const endPoint = config.useExternalUserId
    ? `api/users/${
        (auth as ExternalAuth)?.externalUserId
      }/footprints/consumption`
    : `_ah/api/deeds/v1/footprints/consumption`
  const {mutate: postConsumptionProfile, ...rest} = useMutation(
    ({profile}: {profile: any}) => {
      return client(endPoint, {
        data: {profile},
      })
    },
    {
      onError: () => {
        queryClient.refetchQueries('consumption-profile')
      },
    },
  )

  return {...rest, postConsumptionProfile}
}
