import React, {ReactNode} from 'react'
import { BiError, BiErrorCircle } from "react-icons/bi"
import {styled} from '../context/theme-context/theme-context'

const TooltipContainer = styled.div<{type: string}>`
  width: 100%;
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${p => p.type === 'warning' ? '#F8B200' : 'red'};
  color: #FFFFFF;
`

const TooltipBox = styled.div`
  width:100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  font-size: 14px;
  a {
    color: #FFFFFF;
    text-decoration: underline
  }
`

export const TooltipIcon = styled.span`
  display: flex;
  font-size: 24px;
  margin: 4px 8px 4px 4px;
  cursor: pointer;
`

export const Tooltip = ({
  type = 'warning',
  action = null,
  children
}: {
  type?: 'warning' | 'error',
  action?: any,
  children: ReactNode,
}) => {
  return (
    <TooltipContainer type={type}>
      <TooltipBox>
        <TooltipIcon>
          {
            type === 'warning' ? <BiError /> : <BiErrorCircle />
          }
        </TooltipIcon>
        {children}
      </TooltipBox>
      {action}
    </TooltipContainer>
  )
}