import {ToastContainer, toast, ToastContainerProps} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {useCallback, useState} from 'react'
import {styled} from '../context/theme-context/theme-context'

export function useToast() {
  const [isToastShowed, setIsToastShowed] = useState(false)
  const showToast = useCallback((message: string) => {
    setIsToastShowed(true)
    toast(message, {
      position: 'top-center',
      hideProgressBar: true,
    })
  }, [])

  return {showToast, isToastShowed, ToastContainer: StyledToastContainer}
}

const StyledToastContainer = styled<{variant: 'error' | 'success'} & any>(
  ToastContainer,
).attrs(props => ({
  className: 'toast-container',
  toastClassName: `toast-${props.variant}`,
  bodyClassName: 'body',
  progressClassName: 'progress',
}))`
  .toast-success {
    color: ${p => p.theme.createAccount?.successToast.color || 'black'};
    background-color: ${p =>
      p.theme.createAccount?.successToast.backgroundColor || 'white'};
    border-radius: 16px;
    margin: 0;
    cursor: auto;
  }
  .toast-error {
    color: ${p => p.theme.errorToast?.textColor || 'black'};
    background-color: ${p => p.theme.errorToast?.backgroundColor || 'white'};
    border-radius: 16px;
    margin: 0;
    cursor: auto;
  }
`
