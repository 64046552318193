import React, {FC} from 'react'
import {ThemeProvider} from './theme-context/theme-context'
import {AuthProvider} from './auth-context/auth-context'
import {QueryClient, QueryClientProvider} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'
import {ConfigProvider} from './config-context/config-context'
import {ErrorBoundary} from 'react-error-boundary'
import {PostMessage} from '../types/post-message'
import {customPostMessage} from '../utils/helpers'
import {SimpleErrorFallback} from '../components/simple-error-fallback'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry(failureCount, error) {
        if ((error as any)?.status === 404) return false
        else if (failureCount < 1) return true
        else return false
      },
    },
  },
})

function handleError() {
  const message: PostMessage = {
    source: 'deedsterOnboarding',
    event: 'onboardingError',
  }
  customPostMessage(message, '*')
}

const AppProviders: FC = ({children}) => {
  return (
    <ErrorBoundary
      onError={handleError}
      FallbackComponent={SimpleErrorFallback}
    >
      <ConfigProvider>
        <ThemeProvider>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>{children}</AuthProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </ThemeProvider>
      </ConfigProvider>
    </ErrorBoundary>
  )
}

export {AppProviders}
