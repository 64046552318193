import {ReactComponent as Food} from '../../../assets/category-icons/food.svg'
import {ReactComponent as Commute} from '../../../assets/category-icons/commute.svg'
import {ReactComponent as Travel} from '../../../assets/category-icons/travel.svg'
import {ReactComponent as Housing} from '../../../assets/category-icons/housing.svg'
import {ReactComponent as Shopping} from '../../../assets/category-icons/shopping.svg'

export type Category = 'food' | 'commute' | 'travel' | 'housing' | 'shopping'
export function getIconFromCategory(category: Category) {
  const map = {
    food: Food,
    commute: Commute,
    travel: Travel,
    housing: Housing,
    shopping: Shopping,
  }

  return map[category]
}
