import React, {useLayoutEffect, useState} from 'react'
import { BiRefresh } from "react-icons/bi"
import ClipLoader from 'react-spinners/ClipLoader'
import {Theme} from 'types/theme/theme'
import {SelectCountryStep} from './select-country/select-country-step'
import {FootprintStep} from './footprint/footprint-step'
import {usePreloadImage} from '../../utils/preload-images'

import {QuestionnaireStep} from './questionnaire/questionnaire-step'
import {useConfig} from '../../context/config-context'
import {useTheme} from '../../context/theme-context'
import {FineTune} from './fine-tune/fine-tune'
import {useSteps} from './use-steps'

import {Country} from './select-country/select-country'

import {FixedBackButton} from '../../components/fixed-back-button'
import {FullPageLoader} from '../../components/full-page-loader'
import {Tooltip, TooltipIcon} from '../../components/tooltip'
import {KeepQueryParamLink} from '../../components/keep-query-param-link'
import {useOnboardingViews} from 'api/onboarding'
import {useFootprintResult, usePostAnswers} from 'api/footprint'
import {IntroStep} from './intro/intro-step'
import {useUpdateEmail} from 'modules/update-email/use-update-email'
import {useTranslations} from '../../api/onboarding'

const DrillDown = React.lazy(
  () => import(/* webpackPrefetch: true */ './drill-down/drill-down'),
)
function OnboardingCalculator() {
  const theme: Theme = useTheme()
  const {translations} = useTranslations()
  usePreloadImage(theme.intro?.backgroundImage)
  usePreloadImage(theme.fullPageLoading?.loadingImgSrc)
  const config = useConfig()
  const {views, isLoading: isViewsLoading} = useOnboardingViews()
  const {
    footprintResult,
    isLoading: isFootprintResultLoading,
    isIdle: isFootprintResultIdle,
  } = useFootprintResult()
  const {postAnswers} = usePostAnswers()
  const [selectedCountry, setSelectedCountry] = useState<Country | undefined>()
  const {
    currentStepMeta,
    currentStep,
    setCurrentStep,
    next,
    prev,
    hasPrev,
    setFirstStep,
  } = useSteps(!!footprintResult)
  const {currentUser, refreshUserData, isRefreshing, isEmailVerified} = useUpdateEmail()

  useLayoutEffect(() => {
    if (
      isViewsLoading ||
      (!config.ignoreResultView &&
        (isFootprintResultLoading || isFootprintResultIdle))
    ) {
      setCurrentStep('loading')
    } else if (footprintResult) {
      if (!(currentStep === 'drillDown' && currentStepMeta.category)) {
        setCurrentStep('footprint')
      }
    } else {
      setFirstStep()
    }
  }, [
    //TODO: fix dependency array
    config,
    footprintResult,
    isFootprintResultIdle,
    isFootprintResultLoading,
    isViewsLoading,
    setCurrentStep,
    setFirstStep,
  ])

  if (currentStep === 'loading') {
    return <FullPageLoader />
  }

  const checkIfEmailLinked = () => {
    if(currentUser.providerData){
      return currentUser.providerData.some((v: any) => v.providerId === 'password') 
    } 
    return false
  }

  return (
    <React.Suspense fallback={<FullPageLoader />}>
      {!currentUser.isAnonymous && checkIfEmailLinked() && !isEmailVerified && (
          <Tooltip action={
            <TooltipIcon>
              {
                isRefreshing ?
                  <ClipLoader size={24} color={theme.defaultButton.backgroundColor} /> :
                  <BiRefresh onClick={refreshUserData} />
              }
            </TooltipIcon>
          }>
            <span>
              {translations!['change-email-tooltip-email-verification-pending']}
              {" "}
              <KeepQueryParamLink to="/update-email">
                {
                  translations![
                    'change-email-tooltip-email-verification-pending-link'
                  ]
                }
              </KeepQueryParamLink>
            </span>
          </Tooltip>
        )}
      {hasPrev &&
        currentStep !== 'questionnaire' &&
        currentStep !== 'footprint' &&
        currentStep !== 'finetune' &&
        currentStep !== 'drillDown' && (
          <FixedBackButton
            color={theme.backButtonColor}
            onClick={() => prev()}
          />
        )}

      {currentStep === 'intro' && <IntroStep next={next} />}

      {currentStep === 'selectCountry' && (
        <SelectCountryStep
          onSelect={setSelectedCountry}
          selectedCountry={selectedCountry}
          next={next}
        />
      )}

      {currentStep === 'questionnaire' && (
        <QuestionnaireStep
          showBackButtonOnFirstQuestion={hasPrev}
          onBackOnFirstView={() => prev()}
          views={views!}
          onSubmit={(cleanAnswers, answers) => {
            postAnswers({
              answers: cleanAnswers,
              region: config.countryCode || selectedCountry?.code!,
            })
            setCurrentStep('loading', {showLoadingText: true})
          }}
        />
      )}
      {currentStep === 'footprint' && (
        <FootprintStep
          redo={() => {
            setFirstStep()
          }}
          finetune={category => setCurrentStep('finetune', {category})}
          drillDown={category => setCurrentStep('drillDown', {category})}
          footprintResult={footprintResult!}
        />
      )}

      {currentStep === 'finetune' && (
        <FineTune
          category={currentStepMeta.category}
          onBackClick={category => setCurrentStep('drillDown', {category})}
        />
      )}
      {currentStep === 'drillDown' && (
        <DrillDown
          finetune={category => setCurrentStep('finetune', {category})}
          category={currentStepMeta.category}
          onBackClick={() => setCurrentStep('footprint')}
        />
      )}
    </React.Suspense>
  )
}

export default OnboardingCalculator
