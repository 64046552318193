import React from 'react'
import { useLocation } from 'react-router-dom'

import { useTheme } from '../../context/theme-context'
import { Auth, useAuth } from '../../context/auth-context/auth-context'
import { useConfig } from '../../context/config-context/config-context'
import { useProfileView } from './use-profile-view'
import {
    useAnalyticsCurrentScreen,
    // useAnalyticsLogEvent,
} from '../../utils/use-analytics'
import {
    Container,
    ComponentWrapper,
    UserContainer,
    TitleBar,
    SubTitleBar,
    UserProfile,
    UserBrief,
    //ComparisionCard,
    ImpactsCard,
    Link,
    ComponentWrapperBadges,
    BadgeContainer,
    BadgeTitle,
    LevelText,
    ComponentWrapperAvailableAchievements,
    ImpactsCardZurich
} from '../../components/profile'
import { Graph } from './components/graph'
import { BadgeIcon } from './components/badge'
import { OngoingAchievement } from './components/OngoingAchievement'
import { FullPageLoader } from 'components/full-page-loader'
import { BadgePopup } from "./components/badge-popup"
import { GraphTotalReduction } from './components/graph'
import { translate } from "../../api/localization";
import {ReactComponent as ImpactPointsIcon} from 'assets/icons/impact-points.svg'
import {ReactComponent as RightArrow} from 'assets/icons/right-arrow-white.svg'


function getCookie(name: string): string {
    const nameLenPlus = (name.length + 1);
    return document.cookie
        .split(';')
        .map(c => c.trim())
        .filter(cookie => {
            return cookie.substring(0, nameLenPlus) === `${name}=`;
        })
        .map(cookie => {
            return decodeURIComponent(cookie.substring(nameLenPlus));
        })[0];
    }

const Profile = () => {
    useAnalyticsCurrentScreen('profile')
    const theme = useTheme()

    const auth = useAuth()
    const config = useConfig()
    const location = useLocation()
    const handleQuery = () => {
        return new URLSearchParams(location.search)
    }
    const { profileData } = useProfileView()
    const { data, isLoading } = profileData
    const [activeBadge, setActiveBadge] = React.useState(null)

    const co2ekg = Math.round(data?.profile?.co2e / 1000)
    //const globeTimes = Math.round(co2ekg * 10 / 4800)
    //const phoneTimes = Math.round(co2ekg * 0.833)

    if (isLoading) return (<FullPageLoader />)

    return (
    <React.Fragment>
        <Container background={theme.profile?.backgroundColor}>

            {/*Profile picture, name and total number of deeds*/}
            <UserProfile>
                <UserContainer>
                    <div className="user-view">
                        <img src={data.profile?.profileImage?.small} alt={"profile picture"}/>
                    </div>
                    <UserBrief>{data.profile?.deeds} {translate('profile.deed-count.other')}</UserBrief>
                    <div className="user-name">{data.profile.name}</div>
                </UserContainer>
            </UserProfile>

            {/*card for Zurich impact points card, only show if there is impact points and current challenge has "climate project enabled"*/}
            {data.climateProjectsEnabled &&
                <div>
                <TitleBar>
                    {/*<div>{translate('profile.statistics.headline')}</div>*/}
                    <div>My Impact Points</div> {/*TODO change to phrase string*/}
                </TitleBar>
                <Link href={process.env.REACT_APP_API_URL + "/impact-points/dashboard" +
                        "?user=" + handleQuery().get("user") +
                        "\&apiKey=" + config.apiKey +
                        "\&token=" + (config.token || (auth as Auth)?.token)}>
                    <ComponentWrapper
                        background={theme.profile?.impactCard.backgroundColor}
                        backgroundImage={'background-images/defaultTheme/cloud-bg.png'}
                    >
                        <ImpactsCardZurich>
                            <div className="card-history-wrapper">
                                <p className="earned-text">Earned since start</p> {/*TODO change to phrase string*/}
                                <p className="earned-value">{data.profile?.impactPoints}</p>
                            </div>
                            <div className="card-current-wrapper">
                                <p className="current-text">CURRENT BALANCE</p> {/*TODO change to phrase string*/}
                                <div className="card-value-wrapper">
                                <h1 className="current-value">{data.profile?.impactPoints - data.profile?.impactPointsSpent}</h1>
                                    <ImpactPointsIcon />
                            <RightArrow className="right-arrow" />
                                </div>
                            </div>
                        </ImpactsCardZurich>
                    </ComponentWrapper>
                </Link>
                </div>
            }

            <TitleBar>
                <div>{translate('profile.statistics.headline')}</div>
            </TitleBar>

            {/*Deeds statistics week, month, year*/}
            <ComponentWrapper
                backgroundImage={'background-images/defaultTheme/cloud-bg.png'}
                background={theme.profile?.stats.backgroundColor}

            >
                <Graph
                    data_thisWeek={data.stats?.thisWeek}
                    data_last30Days={data.stats?.last30Days}
                    data_last12Months={data.stats?.last12Months}
                />
            </ComponentWrapper>

            {/*statistics total reduction, only show if there is a reduction*/}
            {data?.stats?.all != null &&
                co2ekg > 0 &&
                <ComponentWrapper
                    backgroundImage={'background-images/defaultTheme/cloud-bg.png'}
                    background={theme.profile?.reduction.backgroundColor}
                >
                    <GraphTotalReduction
                        allData={data.stats?.all}
                        co2e={co2ekg}
                    />
                </ComponentWrapper>}

            {/*card for phone comparison, only show if there is enough saved*/}
            {/* EDIT FEB 2022, numbers not correct hide for now!
                {phoneTimes > 0 &&
                    <ComponentWrapper
                        background={
                            'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(background-images/defaultTheme/charging-phone-background.png) no-repeat 0% 0% / cover'
                        }
                    >
                        <ComparisionCard>
                            <div className="comparison-title">
                                {
                                    translate('profile.comparisons.personal.title-save.other')
                                        .replace(
                                            '{phone-times}',
                                            phoneTimes
                                        )
                                }
                            </div>
                            <div className="comparison-description">
                                {
                                    translate('profile.comparisons.personal.description-save')
                                }
                            </div>
                        </ComparisionCard>
                    </ComponentWrapper>
                } */}

            {/*card for drive comparison, only show if there is enough saved*/}
            {/*
              EDIT FEB 2022, numbers not correct hide for now!
               {globeTimes > 0 &&
                    <ComponentWrapper
                        background={
                            'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(background-images/defaultTheme/globe-times-background.png) no-repeat 0% 0% / cover'
                        }
                    >
                        <ComparisionCard>
                            <div
                                className="comparison-title">{
                                    translate('profile.comparisons.10-friends.title.other')
                                        .replace(
                                            '{globe-times}',
                                            globeTimes
                                        )}</div>
                            <div className="comparison-description">
                                {
                                    translate('profile.comparisons.10-friends.description.other')
                                        .replace(
                                            '{globe-times}',
                                            globeTimes
                                        )
                                        .replace(
                                            '{co2-reduction}',
                                            co2ekg
                                        )}
                            </div>
                        </ComparisionCard>
                    </ComponentWrapper>
                } */}

            {/*card for impact points, only show if there is impact points earned*/}
            {!!data.profile?.impactPoints && (data.profile?.impactPoints != 0) && !data.climateProjectsEnabled &&
                <ComponentWrapper
                    background={theme.profile?.impactCard.backgroundColor}
                    backgroundImage={'background-images/defaultTheme/cloud-bg.png'}
                >
                    <ImpactsCard>
                        <div className="impact-points">{data.profile?.impactPoints}</div>
                        <div className="impact-title">{translate('profile.impact-points.impact-points')}</div>
                        <div>{translate('profile.impact-points.description')}</div>
                    </ImpactsCard>
                </ComponentWrapper>
            }

            {/*Ongoing achievement*/}
            {data.ongoingAchievements[0] &&
                <TitleBar>
                    {translate('profile.badges.title.climate.race')}
                </TitleBar>}
            {data.ongoingAchievements[0] &&
                <ComponentWrapperAvailableAchievements>
                    {data.ongoingAchievements?.map(function (achievement: any, i: number) {
                        return (
                            <div key={'earnedBadge_' + i} onClick={(e: any) => setActiveBadge({
                                ...achievement,
                                available: true,
                                isChallenge: false,
                                isOngoing: true,
                            })}>

                                <OngoingAchievement
                                    achievement={achievement}
                                />
                            </div>
                        )
                    })}
                </ComponentWrapperAvailableAchievements>}
            {/*All Earned Badges - Title*/}
            {((data.earnedAchievements?.length >= 1) ||
              (data.earnedMilestones?.length >= 1) ||
              (data.earnedChallenges?.length >= 1) ||
              (data.earnedClientAchievements?.length >= 1)) &&
                <TitleBar>
                    {translate('profile.badges.title.badges')}
                </TitleBar>
            }
            {/*Earned Achievement - Mission (ex. Achievement) Badge i.e. Rewards*/}
            {(data.earnedAchievements?.length >= 1) &&
                <div>
                    <SubTitleBar>
                        <div>{translate('profile.badges.title.limited-edition')}</div>
                    </SubTitleBar>
                    <ComponentWrapperBadges>
                        {data.earnedAchievements?.map(function (badge: any, i: number) {
                            return (
                                <BadgeContainer key={'earnedBadge_' + i} onClick={(e: any) => setActiveBadge({
                                    ...badge,
                                    available: false,
                                    isChallenge: false,
                                    isOngoing: true
                                })}>
                                    <BadgeIcon imageUrl={badge?.imageUrl}/>
                                    <BadgeTitle>{badge.title}</BadgeTitle>
                                </BadgeContainer>
                            )
                        })}
                    </ComponentWrapperBadges>
                </div>
            }
            {/*Earned Focus Deed badges (i.e. Client Deed badges) */}
            {(data.earnedClientAchievements?.length >= 1) &&
                <div>
                    <SubTitleBar>
                        <div>{translate('profile.badges.title.client-deed')}</div>
                    </SubTitleBar>
                    <ComponentWrapperBadges>
                        {data.earnedClientAchievements?.map(function (badge: any, i: number) {
                            return (
                                <BadgeContainer key={'earnedBadge_' + i} onClick={(e: any) => setActiveBadge({
                                    ...badge,
                                    available: false,
                                    isChallenge: false,
                                    isOngoing: false
                                })}>
                                    <BadgeIcon imageUrl={badge?.imageUrl}
                                    />
                                    <BadgeTitle>{badge.title}</BadgeTitle>
                                    {/* <LevelText>{translate('profile.badges.milestones.level' + badge.level)}</LevelText> */}
                                </BadgeContainer>
                            )
                        })}
                    </ComponentWrapperBadges>
                </div>}
            {/*Earned Milestones - Milestone badges*/}
            {(data.earnedMilestones?.length >= 1) &&
                <div>
                    <SubTitleBar>
                        <div>{translate('profile.badges.title.milestones')}</div>
                    </SubTitleBar>
                    <ComponentWrapperBadges>
                        {data.earnedMilestones?.map(function (badge: any, i: number) {
                            return (
                                <BadgeContainer key={'earnedBadge_' + i} onClick={(e: any) => setActiveBadge({
                                    ...badge,
                                    available: false,
                                    isChallenge: false,
                                    isOngoing: false
                                })}>
                                    <BadgeIcon imageUrl={badge?.imageUrl}
                                    />
                                    <BadgeTitle>{badge.title}</BadgeTitle>
                                    <LevelText>{translate('profile.badges.milestones.level' + badge.level)}</LevelText>

                                </BadgeContainer>
                            )
                        })}
                    </ComponentWrapperBadges>
                </div>
            }
            {/*Earned Challenges through quizz completion*/}
            {(data.earnedChallenges?.length >= 1) &&
                <div>
                    <SubTitleBar>
                        <div>{translate('profile.badges.title.challenges')}</div>
                    </SubTitleBar>
                    <ComponentWrapperBadges>
                        {data.earnedChallenges?.map(function (badge: any, i: number) {
                            return (
                                <BadgeContainer key={'earnedBadge_' + i} onClick={(e: any) => setActiveBadge({
                                    ...badge,
                                    available: false,
                                    isChallenge: true,
                                    isOngoing: false
                                })}>
                                    <BadgeIcon imageUrl={badge?.imageUrl}
                                    />
                                    <BadgeTitle>{badge.title}</BadgeTitle>
                                </BadgeContainer>
                            )
                        })}
                    </ComponentWrapperBadges>
                </div>
            }
        </Container>
        {!!activeBadge && (
            <BadgePopup badge={activeBadge} onClose={() => setActiveBadge(null)}/>
        )}
    </React.Fragment>
)
}

export { Profile }