// Global imports
import {useEffect, useState, useCallback} from 'react'
import isEmail from 'validator/lib/isEmail'
import {useHistory, useLocation} from 'react-router-dom'
import { useMutation } from 'react-query'
import {User} from '@firebase/auth-types'

// Local imports
import {useTranslations} from '../../api/onboarding'
import {useConfig} from '../../context/config-context/config-context'
import {Auth, useAuth} from '../../context/auth-context/auth-context'
import {client} from 'api/api-client'

export function useUpdateEmail() {
  const history = useHistory()
  const location = useLocation()
  const config = useConfig()
  const auth = useAuth()
  const {translations} = useTranslations()
  const {getUpdateEmailUser, getUpdateEmailUserError, isUpdateEmailUserLoading, isUpdateEmailUserSuccess} : any= useUpdateEmailUser()
  const {getResendEmail, isResendEmailLoading, isResendEmailSuccess, isResendEmailError} = useResendEmail()

  const [newEmail, setNewEmail] = useState<{
    text: string
    error?: string | null
  }>({
    text: '',
    error: translations!['new-email-invalid'],
  })
  const [confirmNewEmail, setConfirmNewEmail] = useState<{
    text: string
    error?: string | null
  }>({
    text: '',
    error: translations!['confirm-new-email-invalid'],
  })
  const [updateEmailError, setUpdateEmailError] = useState<{
    code: string
    message?: string | null
  } | null>(null)
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const [isEmailSame, setIsEmailSame] = useState<boolean>(true)
  const [currentUser, setCurrentUser] = useState<User | any>({})
  const [isEmailVerified, setIsEmailVerified] = useState<boolean>(false)
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false)

  const signInAndSetCurrentUser = async () => {
    const res: any | null = await (auth as Auth).signIn()
    if(res) {
      setCurrentUser(res)
      setIsEmailVerified(res.emailVerified)
      return res
    }
    setCurrentUser({})
    setIsEmailVerified(false)
    return null
  }

  useEffect(() => {
    if(getUpdateEmailUserError) {
      (async () => {
        const response: any = await getUpdateEmailUserError.json() 
        if(response.code === 'token-too-old' && config.authProvider) {
          setUpdateEmailError(null)
          const res = await signInAndSetCurrentUser()
          if(res) {
            setUpdateEmail()
          }
        } else {
          setUpdateEmailError(response)
        }
      })()
    } else {
      setUpdateEmailError(null)
    }
  }, [getUpdateEmailUserError])
  
  useEffect(() => {
    if(isUpdateEmailUserSuccess) {
      (async () => {
        await (auth as Auth).signOut()
        await signInAndSetCurrentUser()
      })()
    }
  }, [isUpdateEmailUserSuccess])

  useEffect(() => {
    if((auth as Auth).currentUser) {
        (auth as Auth).currentUser().then((user: User | any) => {
          if(user) {
            setCurrentUser(user)
            setIsEmailVerified(user.emailVerified)
          }
        })}
  }, [auth])

  const handleNewEmailChange = (email: string) => {
    setIsEmailSame(true)
    const {invalidExternalEmail} = config
    const error =
      invalidExternalEmail &&
      email.toLowerCase().includes(invalidExternalEmail.toLowerCase())
        ? translations!['new-email-invalid-part']?.replace(
            '{part}',
            invalidExternalEmail,
          )
        : !isEmail(email)
        ? translations!['new-email-invalid']
        : null
    setNewEmail({
      text: email,
      error,
    })
  }

  const handleConfirmNewEmailChange = (email: string) => {
    setIsEmailSame(true)
    const {invalidExternalEmail} = config
    const error =
      invalidExternalEmail &&
      email.toLowerCase().includes(invalidExternalEmail.toLowerCase())
        ? translations!['confirm-new-email-invalid-part']?.replace(
            '{part}',
            invalidExternalEmail,
          )
        : !isEmail(email)
        ? translations!['confirm-new-email-invalid']
        : null
    setConfirmNewEmail({
      text: email,
      error,
    })
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    setUpdateEmail()
  }

  const setUpdateEmail = async () => {
    setIsSubmitted(true)
    setIsEmailSame(newEmail.text === confirmNewEmail.text)

    if (newEmail.error || confirmNewEmail.error || (newEmail.text !== confirmNewEmail.text)) {
      return
    } else {
       getUpdateEmailUser({newEmail: newEmail.text})
    }
  }

  const handleResendEmail = async (e: any) => {
    e.preventDefault()
    getResendEmail()
  }

  const onUpdateEmailSuccess = useCallback(() => {
    history.push({pathname: '/', search: location.search})
  }, [history, location.search])

  const refreshUserData = () => {
    if((auth as Auth).currentUser) {
        (auth as Auth).currentUser().then(async (user: any) => {
          if(user) {
            setIsRefreshing(true)
            try {
              await user.reload()
              setCurrentUser(user)
              setIsEmailVerified(user.emailVerified)
              setIsRefreshing(false)
            } catch(e) {
              setIsRefreshing(false)
            }
          }
        })}
  }

  return {
    newEmail,
    confirmNewEmail,
    handleNewEmailChange,
    handleConfirmNewEmailChange,
    isEmailSame,
    isSubmitted,
    handleSubmit,
    currentUser,
    history,
    isUpdateEmailUserSuccess,
    isUpdateEmailUserLoading,
    updateEmailError,
    onUpdateEmailSuccess,
    handleResendEmail,
    isResendEmailSuccess,
    isResendEmailLoading,
    isResendEmailError,
    refreshUserData,
    isEmailVerified,
    isRefreshing
  }
}


export function useUpdateEmailUser() {
  const auth = useAuth()
  const {apiKey} = useConfig()

  const {
    mutate: getUpdateEmailUser,
    error: getUpdateEmailUserError,
    isLoading: isUpdateEmailUserLoading,
    isSuccess: isUpdateEmailUserSuccess,
    ...rest
  } = useMutation(({newEmail}: {newEmail: string}) => {
    return client(`_ah/api/deeds/v2/users/${(auth as Auth)?.user?.uid}/change-email-address`, {
      queryParams: {emailAddress: newEmail},
      apiKey, 
      token: (auth as Auth)?.token
    })
  })

  return {
    getUpdateEmailUser,
    getUpdateEmailUserError,
    isUpdateEmailUserLoading,
    isUpdateEmailUserSuccess,
    ...rest
  }
}

export function useResendEmail() {
  const auth = useAuth()
  const {apiKey} = useConfig()

  const {
    mutate: getResendEmail,
    isError: isResendEmailError,
    isLoading: isResendEmailLoading,
    isSuccess: isResendEmailSuccess,
    ...rest
  } = useMutation(() => {
    return client(`_ah/api/deeds/v2/users/${(auth as Auth)?.user?.uid}/verify-email-address`, {
      queryParams: {force: true},
      apiKey, 
      token: (auth as Auth)?.token
    })
  })

  return {
    getResendEmail,
    isResendEmailError,
    isResendEmailLoading,
    isResendEmailSuccess,
    ...rest
  }
}