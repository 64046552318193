import React from 'react'
// core-js contains polyfill which is required to run Firebase Analytics on Android 7 and lower
import 'core-js/stable'

import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import {Auth, useAuth} from './context/auth-context/auth-context'
import {useConfig} from './context/config-context/config-context'
import {useTranslations} from './api/onboarding'
import {CreateAccount} from './modules/create-account/create-account'
import {Profile} from './modules/profile-view/profile-view'
import {FullPageLoader} from './components/full-page-loader'
import OnboardingCalculator from './modules/onboarding-calculator'
import {UpdateEmail} from './modules/update-email/update-email'
import {useAnalyticsCurrentUserId} from './utils/use-analytics'
import {SingleSignOnIntro} from './modules/onboarding-calculator/intro/single-sign-on-intro'
import {Page404} from './components/page-404'
import {BadgePopup} from "./modules/profile-view/components/badge-popup";

const DownloadAppView = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */ './modules/download-the-app-view/download-the-app-view'
    ),
)

function App() {
  const auth = useAuth()
  const config = useConfig()
  const {translations, isLoading} = useTranslations()
  useAnalyticsCurrentUserId()

  if (isLoading) {
    return <FullPageLoader delaySpinner={config.useExternalUserId ? 400 : 0} />
  }

  if (!!config.authProvider && !(auth as Auth)?.user) {
    return <SingleSignOnIntro />
  }
  return (
    <React.Suspense fallback={<FullPageLoader />}>
      <Router>
        <Switch>
          <Route path="/profile" component={Profile} />
          <Route path="/signup" component={CreateAccount} />
          <Route path="/update-email" component={UpdateEmail} />
          <Route path="/badgepopup" component={BadgePopup} />
          {translations && translations!['footprint-button'] && (
            <Route path="/download-app" component={DownloadAppView} />
          )}
          <Route exact path="/" component={OnboardingCalculator} />
          <Route path="*" component={Page404} />
        </Switch>
      </Router>
    </React.Suspense>
  )
}

export default App
