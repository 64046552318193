import {useErrorHandler} from 'react-error-boundary'
import {useQuery} from 'react-query'
import {useAuthClient} from '../api-client'

var selectedLocale : any | null

export const useLocalization = ({
                                    category,
                                    language,
                                    userId
                                }: {
    category: string,
    language: string | null,
    userId: string | null
}) => {
    const client = useAuthClient()

    const {data: localizationData, isSuccess: islocalizationSuccess, ...result} = useQuery('localization', () => {
        return client(`api/localization/${category}`,
                        {
                          queryParams: {
                            ...(language && {language: language}),
                            ...(userId && {userId: userId}),
                          },
                        })
    })


    useErrorHandler(result.error)

    selectedLocale = localizationData
    return {localizationData, islocalizationSuccess, ...result}
}


export function translate(keyPath: string) {
    if(selectedLocale == null) {
        return ""
    }

    const keys = keyPath.split(".")
    var result = selectedLocale
    for(let k of keys) {
        if(result == null)
            break

        // Walk down the key path
        result = result[k]
    }

    if(result == null) {
        console.log("ERROR: Missing translation key '" + keyPath + "'")
    }

    return result
}