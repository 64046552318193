import {useCallback, useEffect, useState} from 'react'
import isEmail from 'validator/lib/isEmail'
import {useHistory, useLocation} from 'react-router-dom'
import {useMutation} from 'react-query'

import {
  Auth,
  ExternalAuth,
  useAuth,
  useLinkAccount,
} from '../../context/auth-context/auth-context'
import {useConfig} from '../../context/config-context/config-context'
import {useAuthClient} from '../../api/api-client'
import {useAnalyticsLogEvent} from '../../utils/use-analytics'
import {useTranslations} from '../../api/onboarding'

export function useCreateAccountForm() {
  const history = useHistory()
  const auth = useAuth()
  const {translations} = useTranslations()
  const location = useLocation()
  const config = useConfig()
  const {acceptTerms, acceptTermsError} = useAcceptTerms()

  const {acceptTermsAndLinkAccount, isSuccess, isLoading, error} =
    useAcceptTermsAndLinkAccount()

  const [email, setEmail] = useState<{
    text: string
    error?: string | null
  }>({
    text: '',
    error: translations!['signup-email-invalid'],
  })

  const [password, setPassword] = useState<{
    text: string
    error?: string | null
  }>({text: '', error: translations!['signup-password-too-weak']})

  const [confirmPassword, setConfirmPassword] = useState<{
    text: string
    error?: string | null
  }>({
    text: '',
    error: translations!['signup-passwords-do-not-match'],
  })

  const [isTermsAccepted, setIsTermsAccepted] = useState(false)

  const handleEmailChange = (email: string) => {
    const {invalidExternalEmail} = config
    const error =
      invalidExternalEmail &&
      email.toLowerCase().includes(invalidExternalEmail.toLowerCase())
        ? translations!['signup-email-invalid-part']?.replace(
            '{part}',
            invalidExternalEmail,
          )
        : !isEmail(email)
        ? translations!['signup-email-invalid']
        : null
    setEmail({text: email, error})
  }

  const handlePasswordChange = (password: string) => {
    const securePasswordPattern = new RegExp(
      config.securePasswordPattern || '(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}',
    )
    const error = securePasswordPattern.test(password)
      ? null
      : translations!['signup-password-too-weak']
    setPassword({text: password, error})
  }
  const handleConfirmPasswordChange = (confirmPassword: string) => {
    const error =
      confirmPassword !== password.text
        ? translations!['signup-passwords-do-not-match']
        : null
    setConfirmPassword({text: confirmPassword, error})
  }

  const [isSubmitted, setIsSubmitted] = useState(false)

  const handleSubmit = async (e: any) => {
    setIsSubmitted(true)
    e.preventDefault()
    if (
      email.error ||
      password.error ||
      confirmPassword.error ||
      !isTermsAccepted
    ) {
      return
    }

    await acceptTermsAndLinkAccount({
      email: email.text,
      password: password.text,
    })
  }

  const onCreateAccountSuccess = useCallback(() => {
    history.push({pathname: '/', search: location.search})
  }, [history, location.search])

  useEffect(() => {
    if ((error as any)?.code === 'auth/requires-recent-login') {
      setTimeout(() => (auth as Auth).signOut(), 4000)
    }
  }, [auth, error])

  useEffect(() => {
    if (
      (!isSubmitted && (auth as Auth).hasPasswordProvider()) ||
      config.useExternalUserId
    ) {
      onCreateAccountSuccess()
    }
  }, [isSubmitted, auth, config, onCreateAccountSuccess])

  return {
    handleEmailChange,
    handlePasswordChange,
    handleConfirmPasswordChange,
    email,
    password,
    confirmPassword,
    setIsTermsAccepted,
    isTermsAccepted,
    isSubmitted,
    error,
    acceptTermsError,
    handleSubmit,
    isLoading,
    onCreateAccountSuccess,
    isSuccess,
  }
}

export function useAcceptTerms() {
  const client = useAuthClient()
  const config = useConfig()
  const auth = useAuth()
  const endPoint = config.useExternalUserId
    ? `api/users/${
        (auth as ExternalAuth)?.externalUserId
      }/terms/NetZeroYou2/accept`
    : `_ah/api/deeds/v2/terms/NetZeroYou2/accept`
  const {
    mutateAsync: acceptTerms,
    error: acceptTermsError,
    isLoading: isAcceptTermsLoading,
    isSuccess: isAcceptTermsSuccess,

    ...rest
  } = useMutation(() => client(endPoint, {data: {version: '2021-04-15'}}))

  return {
    acceptTerms,
    acceptTermsError,
    isAcceptTermsSuccess,
    isAcceptTermsLoading,
    ...rest,
  }
}

export function useAcceptTermsAndLinkAccount() {
  const logEvent = useAnalyticsLogEvent()
  const {
    acceptTerms,
    acceptTermsError,
    isAcceptTermsSuccess,
    isAcceptTermsLoading,
  } = useAcceptTerms()
  const {
    linkAccount,
    isLinkAccountSuccess,
    isLinkAccountLoading,
    linkAccountError,
  } = useLinkAccount()

  async function acceptTermsAndLinkAccount({
    email,
    password,
  }: {
    email: string
    password: string
  }) {
    await acceptTerms()
    await linkAccount({email, password})
  }

  return {
    acceptTermsAndLinkAccount,
    error: acceptTermsError ?? linkAccountError,
    isSuccess: isAcceptTermsSuccess && isLinkAccountSuccess,
    isLoading: isAcceptTermsLoading || isLinkAccountLoading,
  }
}
