import 'firebase/analytics'
import {useEffect} from 'react'
import {isExternalAuth, useAuth} from '../context/auth-context'
import {useFirebase} from './use-firebase'
import {useConfig} from '../context/config-context'

export function useAnalyticsCurrentUserId() {
  const auth = useAuth()
  const firebase = useFirebase()
  const config = useConfig()

  useEffect(() => {
    //The firebase app is initialized in auth-context.tsx
    if (!firebase.apps.length) {
      return
    }
    if (isExternalAuth(auth)) {
      firebase.analytics().setUserId(auth.externalUserId)
    } else if (auth.user) {
      firebase.analytics().setUserId(auth.user.uid)
    }
    firebase.analytics().setUserProperties({
      config: config.configId,
    })
  }, [auth, config, firebase])
}

export function useAnalyticsCurrentScreen(screenName: string) {
  const firebase = useFirebase()
  useEffect(() => {
    firebase.analytics().setCurrentScreen(screenName)
  }, [firebase, screenName])
}

export function useAnalyticsLogEvent() {
  const firebase = useFirebase()
  return (eventName: string, extra?: Record<string, any>) => {
    firebase.analytics().logEvent(eventName, extra)
  }
}
