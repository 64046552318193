import React from 'react'
import ReactDOM from 'react-dom'
import App from './app'
import './bootstrap'
import GlobalStyles from './style/global-styles'
import {AppProviders} from './context/app-providers'
import ResizeObserver from 'resize-observer-polyfill'


if (!window.ResizeObserver) {
  // ResizeObserver is not available < iOS 13.1, hence use resize-observer-polyfill instead
  window.ResizeObserver = ResizeObserver
}


ReactDOM.render(
  <AppProviders>
    <GlobalStyles />
    <App />
  </AppProviders>,

  document.getElementById('root'),
)
