import {Category} from '../modules/onboarding-calculator/onboarding-calculator.helpers.ts/category-related'
import {Answers} from '../api/onboarding/types/onboarding-views'
import {Auth, ExternalAuth} from '../context/auth-context'

export function checkCondition(
  condition: Condition,
  answers: Answers,
): boolean {
  if (isCompositeCondition(condition)) {
    switch (condition.op) {
      case 'and':
        return condition.conditions.every(c => checkCondition(c, answers))
      case 'or':
        return condition.conditions.some(c => checkCondition(c, answers))
      case 'not':
        return !condition.conditions.every(c => checkCondition(c, answers))
    }
  } else {
    const operators: Record<Operation, (l: any, r: any) => boolean> = {
      eq: (l, r) => l == r,
      gt: (l, r) => l > r,
      gte: (l, r) => l >= r,
      lt: (l, r) => l < r,
      lte: (l, r) => l <= r,
    }

    const {path} = condition
    const pathValue = answers[path[0]][path[1]]

    return operators[condition.op](pathValue, condition.value)
  }
}

export type ExtendedCategory = Category | 'car' | 'recycle'

export type Path = [Category, string]

export type Condition = SimpleCondition | CompositeCondition
export interface SimpleCondition {
  op: Operation
  path: [Category, string] | [ExtendedCategory, string]
  value: number | string | boolean | null | Path
}

export type Operation = 'eq' | 'lt' | 'lte' | 'gt' | 'gte'

export interface CompositeCondition {
  op: CompositeOperation
  conditions: Condition[]
}

type CompositeOperation = 'or' | 'and' | 'not'

export function isCompositeCondition(
  condition: Condition,
): condition is CompositeCondition {
  return Boolean((condition as CompositeCondition).conditions)
}
