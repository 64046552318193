import 'firebase/auth'
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import {useAsync} from 'utils/use-async'
import {User} from '@firebase/auth-types'
import {client} from 'api/api-client'
import {useConfig} from '../config-context/config-context'
import {useErrorHandler} from 'react-error-boundary'
import {useMutation, useQueryClient} from 'react-query'
import {FullPageLoader} from 'components/full-page-loader'
import {useGetExternalUserId} from './auth-context.helpers'
import {useNavigateAndKeepQueryParams} from 'utils/useNavigateAndKeepQueryParams'
import {useFirebase} from 'utils/use-firebase'
import {useToast} from 'utils/use-toast'
import {useTranslations} from 'api/onboarding'

export interface Auth {
  user: User | undefined
  token: string | undefined
  hasPasswordProvider: () => boolean
  signInAnonymously: () => void
  signIn: () => void
  signOut: () => void
  isLoading: boolean
  explicitSignInRequired: boolean
  linkAccount: ({email, password}: {email: string; password: string}) => any
  currentUser: () => any
}
export interface ExternalAuth {
  externalUserId: string
}
const AuthContext = createContext({} as Auth | ExternalAuth)
AuthContext.displayName = 'AuthContext'

export const AuthProvider = ({children}: {children: ReactNode}) => {
  const {postOIDCUserProfile, postOIDCUserProfileError} =
    usePostOIDCUserProfile()
  const externalUserId = useGetExternalUserId()
  const {translations, isLoading: isTranslationsLoading} = useTranslations()
  const {ToastContainer, isToastShowed, showToast: showErrorToast} = useToast()
  const config = useConfig()
  const firebase = useFirebase()
  const {isLoading, isIdle, setData, setError, error, data} = useAsync<{
    user: User
    token: string
  } | null>()
  useErrorHandler(error)

  const hasPasswordProvider = useCallback(() => {
    if (data == null) {
      return false
    }

    const {user} = data
    return user.providerData?.some(
      provider => provider?.providerId === 'password',
    )
  }, [data])

  const linkAccount = useCallback(
    async ({email, password}: {email: string; password: string}) => {
      if (!firebase.auth().currentUser) {
        throw new Error(translations!['signup-must-sign-in-to-link'])
      }

      const credential = firebase.auth.EmailAuthProvider.credential(
        email,
        password,
      )

      await firebase
        .auth()
        .currentUser?.linkWithCredential(credential)
        .then(async ({user}) => {
          const token = await user?.getIdToken()
          setData({user, token})
          if (process.env.NODE_ENV === 'development') {
            console.log('Account linking success', user)
          }
        })
    },
    [firebase, setData, translations],
  )

  const signOut = useCallback(async () => {
    await firebase.auth().signOut()
  }, [firebase])

  const currentUser = useCallback(async () => {
    return firebase.auth().currentUser
  }, [firebase])

  const signIn = useCallback(async () => {
    return new Promise((resolve, reject) => {
      const provider = new firebase.auth.OAuthProvider(config.authProvider!)
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(async result => {
          const user = result?.user
          if (user == null) {
            console.error(`signInWithPopup returned no user`)
            showErrorToast(translations!['signup-login-failed'])
            return reject()
          }
          const token = await user.getIdToken()

          setData({user, token})
          postOIDCUserProfile({
            profile: result?.additionalUserInfo?.profile,
            token,
          })
          resolve(user)
        })
        .catch(error => {
          console.error(error.message)
          showErrorToast(translations!['signup-login-failed'])
          setData(null)
          reject()
        })
    })
  }, [config.authProvider, firebase, showErrorToast, translations])

  const signInAnonymously = useCallback(async () => {
    await firebase.auth().signInAnonymously().catch(setError)
  }, [firebase, setError])

  useEffect(() => {
    if (config.useExternalUserId) {
      return
    }

    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => {
        firebase.auth().onAuthStateChanged(async user => {
          if (process.env.NODE_ENV === 'development') {
            console.log('auth context - user', user)
            console.log('auth context - token', {token: user?.getIdToken()})
          }

          const setUserData = async () => {
            const token = await user?.getIdToken()
            setData({user, token})
          }

          if (user) {
            if (!config.anonymousUser) {
              if (!user.isAnonymous) {
                await setUserData()
              } else {
                setData(null)
                await signOut()
              }
            } else {
              await setUserData()
            }
          } else {
            setData(null)
            if (config.anonymousUser) {
              signInAnonymously()
            }
          }

          // OLD LOGIC

          // const currentUserIsCorrectAuthType = Boolean(
          //   user && Boolean(user?.isAnonymous) === Boolean(config.anonymousUser),
          // )

          // if (!user || !currentUserIsCorrectAuthType) {
          //   // setData(null)
          //   // if (!currentUserIsCorrectAuthType && user) {
          //   //   await signOut()
          //   // }

          //   if(user) {
          //     const token = await user?.getIdToken()
          //     setData({user, token})
          //   } else {
          //     if (config.anonymousUser) {
          //       await signInAnonymously()
          //     }
          //   }
          // } else {
          //   const token = await user.getIdToken()
          //   setData({user, token})
          // }
        })
      })
  }, [setData, signInAnonymously, signOut, config, firebase])

  useEffect(() => {
    if (postOIDCUserProfileError && translations) {
      showErrorToast(translations!['signup-access-denied'])
    }
  }, [postOIDCUserProfileError, showErrorToast, translations])

  const user = data?.user
  const token = data?.token
  const value: Auth = useMemo(
    () => ({
      signInAnonymously,
      user,
      token,
      signOut,
      signIn,
      hasPasswordProvider,
      explicitSignInRequired: Boolean(
        config.authProvider && !user && !isLoading,
      ),
      isLoading: isLoading || isIdle,
      linkAccount,
      currentUser,
    }),
    [
      signInAnonymously,
      user,
      token,
      hasPasswordProvider,
      signIn,
      signOut,
      config,
      isLoading,
      isIdle,
      linkAccount,
      currentUser,
    ],
  )
  if (config.useExternalUserId) {
    return (
      <AuthContext.Provider value={{externalUserId}}>
        {children}
      </AuthContext.Provider>
    )
  } else if (isLoading || isTranslationsLoading || isIdle) {
    return <FullPageLoader />
  } else {
    // @ts-ignore
    return (
      <AuthContext.Provider value={value}>
        {isToastShowed && <ToastContainer variant="error" />}
        {children}
      </AuthContext.Provider>
    )
  }
}

export function useAuth() {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within an AuthProvider`)
  }
  return context
}

export function usePostOIDCUserProfile() {
  const firebase = useFirebase()
  const queryClient = useQueryClient()
  const {apiKey} = useConfig()
  const {
    mutate: postOIDCUserProfile,
    error: postOIDCUserProfileError,
    isLoading: isPostOIDCUserProfileLoading,
    isSuccess: isPostOIDCUserProfileSuccess,
    ...rest
  } = useMutation(
    ({profile, token}: {profile: any; token: string}) => {
      return client('_ah/api/deeds/v2/users/validate', {
        data: {profile},
        apiKey,
        token,
      })
    },
    {
      onError: () => {
        console.error('Validate user failed, logging out user')
        firebase.auth().signOut()
        queryClient.removeQueries()
      },
    },
  )

  return {
    postOIDCUserProfile,
    postOIDCUserProfileError,
    isPostOIDCUserProfileLoading,
    isPostOIDCUserProfileSuccess,
    ...rest,
  }
}

export function useLinkAccount() {
  const auth = useAuth() as Auth
  const {
    mutate: linkAccount,
    error: linkAccountError,
    isLoading: isLinkAccountLoading,
    isSuccess: isLinkAccountSuccess,
    ...rest
  } = useMutation((cred: {email: string; password: string}) =>
    auth?.linkAccount(cred),
  )

  return {
    linkAccount,
    linkAccountError,
    isLinkAccountLoading,
    isLinkAccountSuccess,
    ...rest,
  }
}

export function useLogout() {
  const navigate = useNavigateAndKeepQueryParams()
  const auth = useAuth() as Auth
  const queryClient = useQueryClient()

  return useCallback(() => {
    auth.signOut()
    queryClient.removeQueries()
    navigate('/')
  }, [auth, navigate, queryClient])
}

export function isExternalAuth(
  auth: Auth | ExternalAuth,
): auth is ExternalAuth {
  return !!(auth as unknown as ExternalAuth)?.externalUserId
}
