import {useCallback, useMemo, useState} from 'react'
import {useConfig} from '../../context/config-context/config-context'
import {useAnalyticsLogEvent} from '../../utils/use-analytics'

export type StepName =
  | 'loading'
  | 'signin'
  | 'intro'
  | 'selectCountry'
  | 'questionnaire'
  | 'footprint'
  | 'drillDown'
  | 'finetune'

const Steps: StepName[] = [
  'loading',
  'signin',
  'intro',
  'selectCountry',
  'questionnaire',
  'footprint',
  'drillDown',
  'finetune',
]

const firstStep = 2 // Ignore loading and signin

export function useSteps(hasFootprintResult: boolean) {
  const config = useConfig()
  const logEvent = useAnalyticsLogEvent()
  const [currentStepIndex, setCurrentStepIndex] = useState<{
    index: number
    meta?: any
  }>({index: -1})
  const activeSteps = useMemo(
    () =>
      Steps.filter(step => {
        if (step === 'intro' && config.ignoreIntroView) {
          return false
        }
        if (
          (step === 'footprint' || step === 'finetune') &&
          config.ignoreResultView
        ) {
          return false
        }

        if (step === 'selectCountry' && config.countryCode) {
          return false
        }

        return true
      }),
    [config],
  )
  const currentStep = activeSteps?.[currentStepIndex.index]
  const currentStepMeta = currentStepIndex.meta
  const lastStep = activeSteps.length - 1
  const hasPrev = currentStepIndex.index > firstStep || hasFootprintResult

  const next = () => {
    const nextStepIndex = Math.min(currentStepIndex.index + 1, lastStep)
    setCurrentStepIndex({index: nextStepIndex})
  }
  const prev = () => {
    if (currentStepIndex.index === firstStep && hasFootprintResult) {
      setCurrentStep('footprint')
    } else {
      const prevStepIndex = Math.max(currentStepIndex.index - 1, 0)
      setCurrentStepIndex({index: prevStepIndex})
    }
  }

  const setFirstStep = useCallback(
    () => {
      logEvent('first-view',
        {language: config.language,
         countryCode: config.countryCode
      })
     return setCurrentStepIndex({index: firstStep})
    },
    [setCurrentStepIndex],
  )
  const setCurrentStep = useCallback(
    (type: StepName, meta?: any) => {
      const index = activeSteps.indexOf(type)
      setCurrentStepIndex({index, meta})
    },
    [activeSteps],
  )

  return {
    currentStep,
    currentStepMeta,
    setFirstStep,
    setCurrentStep,
    next,
    prev,
    hasPrev,
  }
}
