import React from 'react'

import {breakpoint} from '../../../style/media-queries'

import {styled, useTheme} from '../../../context/theme-context/theme-context'
import {FootprintCarousel} from './footprint-carousel'
import {useScrollLock} from '../../../utils/use-lock-body-scroll'
import {isTouchDevice} from '../../../utils/helpers'
import {usePreloadImage} from '../../../utils/preload-images'

import {useConfig} from '../../../context/config-context/config-context'
import {Category} from '../onboarding-calculator.helpers.ts/category-related'
import {Button, TextButton} from '../../../components/button'
import {Logo, PageWrapper} from '../../../components/page-wrapper'
import {FullPageLoader} from '../../../components/full-page-loader'
import {KeepQueryParamLink} from '../../../components/keep-query-param-link'
import {useTranslations} from '../../../api/onboarding'
import {usePrefetchConsumptionProfile} from '../../../api/consumption-profile'
import {FootprintResult, useFootprintResult} from 'api/footprint'
import {Link} from 'react-router-dom'
import {
  useAnalyticsCurrentScreen,
  useAnalyticsLogEvent,
} from '../../../utils/use-analytics'

interface FootprintProps {
  footprintResult: FootprintResult
  redo: () => void
  finetune: (category: Category) => void
  drillDown: (category: Category) => void
}

const FootprintStep = ({footprintResult, redo, drillDown}: FootprintProps) => {
  useAnalyticsCurrentScreen('footprint')
  const logEvent = useAnalyticsLogEvent()
  const config = useConfig()
  const {isFetching} = useFootprintResult()
  usePrefetchConsumptionProfile()
  const setTargetElement = useScrollLock()
  const theme = useTheme()
  const {translations} = useTranslations()
  usePreloadImage(theme.intro?.backgroundImage)

  if (isFetching) {
    return <FullPageLoader />
  }

  return (
    <Wrapper
      background={theme.footprint!.background}
      backgroundImage={theme.footprint?.backgroundImage}
      ref={setTargetElement}
    >
      <FootprintCarousel
        onCategoryClick={drillDown}
        footprintResult={footprintResult}
      />

      {theme.footprint?.logo &&
          <Logo src={theme.footprint?.logo} alt={'logo'}/>
      }
      <BottomPart>
        <TextWrapper>
          <p>
            {isTouchDevice()
              ? translations!['footprint-text-mobile']
              : translations!['footprint-text']}
          </p>
        </TextWrapper>

        <TextButton
          color={theme.footprint?.text}
          onClick={() => {
            logEvent('redo-onboarding')
            redo()
          }}
        >
          {translations!['redo-onboarding-button']}
        </TextButton>



        {config.footprintContinueLink &&
            <Button
                color={theme.defaultButton.textColor}
                radius={theme.defaultButton.borderRadius}
                background={theme.defaultButton.backgroundColor}
                as={config.keepContinueQueryParams ? KeepQueryParamLink : Link}
                to={config.keepContinueQueryParams ? config.footprintContinueLink as string : {pathname: config.footprintContinueLink}}
                target={config.keepContinueQueryParams ? "" : "_blank"}
            >
              {translations!['footprint-button'] ? translations!['footprint-button'] : 'Continue'}
            </Button>}


      </BottomPart>


    </Wrapper>
  )
}

const Wrapper = styled(PageWrapper)`
  overflow: hidden;
`

const BottomPart = styled.div`
  max-width: 402px;
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 50px;
  z-index: 99999;
  padding: 0 25px;
  text-align: center;
  ${breakpoint.xs} {
    bottom: 10px;
  }

  ${breakpoint.sm} {
    bottom: 8vh;
  }

  ${breakpoint.mobileLandscape} {
    bottom: 4vh;
  }

  p {
    margin-bottom: 0;
  }

  button {
    margin-bottom: 40px;
  }
`
const TextWrapper = styled.div`
  max-width: 350px;
  text-align: center;
  z-index: 0;
  line-height: 20px;
  color: ${({theme}) => theme.footprint!.text};
  margin-bottom: 7px;
  ${breakpoint.sm} {
    max-width: 450px;
  }
  ${breakpoint.xs} {
    display: none;
  }
  
  ${breakpoint.lowHeight} {
    display: none;
  }
  
  > h2 {
    font-size: 18px;
    margin: 0;
    ${breakpoint.sm} {
      font-size: 27px;
    }
  }
  > p {
    font-size: 13px;
    ${breakpoint.sm} {
      font-size: 13px;
    }

    ${breakpoint.mobileLandscape} {
      margin: 0;
      font-size: 13px;
    }
  }
`


export {FootprintStep}
