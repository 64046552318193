import * as React from 'react'

function useSafeDispatch<T>(dispatch: React.Dispatch<T>) {
  const mounted = React.useRef(false)
  React.useLayoutEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])
  return React.useCallback(
    action => (mounted.current ? dispatch(action) : void 0),
    [dispatch],
  )
}

interface stateInterface<T = unknown> {
  status: 'idle' | 'pending' | 'resolved' | 'rejected'
  data: T
  error: unknown
}
const defaultInitialState: stateInterface = {
  status: 'idle',
  data: null,
  error: null,
}
function useAsync<T>(initialState?: stateInterface<T>) {
  const initialStateRef = React.useRef({
    ...defaultInitialState,
    ...initialState,
  })
  const [{status, data, error}, setState] = React.useReducer(
    (s: stateInterface, a: stateInterface) => ({...s, ...a}),
    initialStateRef.current,
  )

  const safeSetState = useSafeDispatch<stateInterface<T>>(setState)

  const setData = React.useCallback(
    data => safeSetState({data, status: 'resolved'}),
    [safeSetState],
  )
  const setError = React.useCallback(
    error => safeSetState({error, status: 'rejected'}),
    [safeSetState],
  )
  const reset = React.useCallback(() => safeSetState(initialStateRef.current), [
    safeSetState,
  ])

  const run = React.useCallback(
    promise => {
      if (!promise || !promise.then) {
        throw new Error(
          `The argument passed to useAsync().run must be a promise. Maybe a function that's passed isn't returning anything?`,
        )
      }
      safeSetState({status: 'pending'})
      return promise.then(
        (data: T) => {
          setData(data)
          return data
        },
        (error: unknown) => {
          setError(error)
          return Promise.reject(error)
        },
      )
    },
    [safeSetState, setData, setError],
  )

  const typedData = data as T
  return {
    // using the same names that react-query uses for convenience
    isIdle: status === 'idle',
    isLoading: status === 'pending',
    isError: status === 'rejected',
    isSuccess: status === 'resolved',

    setData,
    setError,
    error,
    status,
    data: typedData,
    run,
    reset,
  }
}

export {useAsync}
