import {styled} from "../../../context/theme-context";
import {BadgeIcon} from './badge'
import {translate} from '../../../api/localization'
import {ReactComponent as Checkmark} from '../../../assets/profile/check_circle.svg'
import React from "react";
import {ReactComponent as CalendarIcon} from '../../../assets/profile/calendar.svg'


type AchievementProps = {
    achievement?: any;
};


type AchievementTopProps = {
    backgroundImage?: string;
};

const AchievementText = styled.div`
  flex-grow: 1;
  font-size: 13px;
  padding: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const Achievement = styled.div`
  margin-top: 16px;
  background-color: white;
  filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.05));
  border-radius: 10px;
`

export const AchievementTop = styled.div<AchievementTopProps>`
  background: ${({backgroundImage}) =>
          `linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url('${backgroundImage}')`};
  background-color: grey;
  background-size: cover;
  padding: 20px;
  -moz-border-radius: 0;
  -webkit-border-radius: 10px 10px 0 0;
  border-radius: 10px 10px 0 0;
`

const AchievementTitle = styled.h2`
  font-weight: 500;
  font-size: 28px;
  line-height: 120%;
  letter-spacing: -0.2px;
  color: #FFFFFF;
  margin-top: 12px;
`

const AchievementDescription = styled.p`
  font-weight: 100;
  font-size: 17px;
  line-height: 120%;
  color: #FFFFFF;
  margin-top: 16px;
  margin-bottom: 0;
`
const AchievementDate = styled.div`
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  flex-direction: row;
  gap: 12px;
`
const AchievementProgress = styled.p`
  font-weight: 400;
  margin-top: -2px;
  margin-bottom: 0;
`

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const BadgeToEarnContainer = styled.div`
  width: 50px;
  height: 50px;
`
const Progress = styled.span`
  font-weight: 700;
`

type ProgressFunctiontProps = {
    progress: number;
    target: number;
    unit: string;
};


const ProgressFunction = ({progress, target, unit}: ProgressFunctiontProps) => {
    return (
        <React.Fragment>
            {(target != null) &&
             !isNaN(target) &&
             (target != 0) &&
                <AchievementProgress>
                    {(progress || 0) < target
                        ? <React.Fragment><Progress>{progress || 0}</Progress>/</React.Fragment>
                        : <Checkmark style={{display: 'inline-block', marginRight: '4px'}}/>}

                    {target.toLocaleString()} {unit}
                </AchievementProgress>}
        </React.Fragment>
    )
}

export const OngoingAchievement = ({achievement}: AchievementProps) => {
    return (
        <Achievement>
            <AchievementTop
                backgroundImage={achievement.ongoingImageUrl}
            >
                <AchievementTitle>{achievement.title}</AchievementTitle>
                {achievement.startDateTimeLabel &&
                 achievement.endDateTimeLabel &&
                    <AchievementDate>
                        <CalendarIcon/>
                        <div>{achievement.startDateTimeLabel} {translate('profile.badges.date-to')} {achievement.endDateTimeLabel}</div>
                    </AchievementDate>}
                <AchievementDescription>{achievement.availableDescription}</AchievementDescription>
            </AchievementTop>
            <AchievementText>

                <OngoingAchievementProgress achievement={achievement}/>
                <BadgeToEarnContainer>
                    <BadgeIcon imageUrl={achievement?.imageUrl}
                    />
                </BadgeToEarnContainer>
            </AchievementText>
        </Achievement>
    )
}

export const OngoingAchievementProgress = ({achievement}: AchievementProps) => {

    return (
        <ProgressContainer>
            <ProgressFunction progress={Number(achievement.progress?.impactPoints)}
                              target={Number(achievement.progressTarget?.impactPoints)}
                              unit={translate('profile.badges.impact-points')}/>
            <ProgressFunction progress={Number(achievement.progress?.deedCount)}
                              target={Number(achievement.progressTarget?.deedCount)}
                              unit={translate('profile.badges.deeds')}/>
            <ProgressFunction progress={Number(achievement.progress?.journeyCount)}
                              target={Number(achievement.progressTarget?.journeyCount)}
                              unit={translate('profile.badges.challenges')}/>
            <ProgressFunction progress={Number(achievement.progress?.co2e)}
                              target={(Number(achievement.progressTarget?.co2e) || 0) / 1000}
                              unit={translate('profile.badges.kg-co2e')}/>
            <ProgressFunction progress={Number(achievement.progress?.completions)}
                              target={(Number(achievement.progressTarget?.completions))}
                              unit={translate('profile.badges.times-completion')}/>
        </ProgressContainer>


    )
}