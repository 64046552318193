import {styled} from '../../../context/theme-context'
import {BadgeContainer, ComponentWrapper, LevelText} from "../../../components/profile";
import {BadgeIcon} from "./badge";
import {ReactComponent as CloseIcon} from '../../../assets/icons/close.svg'
import {translate} from '../../../api/localization'
import React, {useRef} from "react";
import {OngoingAchievementProgress} from './OngoingAchievement';

const AbsoluteWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  padding: 15px;
  position: fixed;
  top: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.6);
`

const BadgeWrapper = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: center;
`

const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 24px;
  height: 24px;
  margin: 18px;
`

const BadgeTitle = styled.h2`
  color: #000000;
  margin-bottom: 8px;
  text-align: center;
  margin-top: 8px;
  letter-spacing: -0.2px;
`

const BadgeDescription = styled.p`
  width: 100%;
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 17px;
  line-height: 120%;
  color: #2C2927;
  text-align: left;
  letter-spacing: -0.2px;
`

const ReStyleProgress = styled.div`
  color: #000000;
  margin-bottom: 18px;
  font-size: 13px;
`

const QuizMasterDescription = styled.p`
  width: 100%;
  margin-top: 16px;
  margin-bottom: 8px;
  font-size: 15px;
  line-height: 120%;
  color: #6E6A68;
  letter-spacing: -0.2px;
  font-weight: 100;
`

type BadgePopupProps = {
    badge: any;
    onClose: () => void;
};

export const BadgePopup = ({badge, onClose}: BadgePopupProps) => {
    const anchorRef = useRef<HTMLDivElement>(null);

    const handleClosePopup = (e: any) => {
        if (anchorRef && anchorRef.current && anchorRef.current.contains(e.target)) return;
        onClose();
    };

    return (
        <AbsoluteWrapper onClick={handleClosePopup}>
            <ComponentWrapper
                ref={anchorRef}
            >
                <CloseButton onClick={onClose}>
                    <CloseIcon/>
                </CloseButton>
                <BadgeWrapper>
                    <BadgeContainer>
                        <BadgeIcon imageUrl={badge?.imageUrl}
                        />
                    </BadgeContainer>
                </BadgeWrapper>
                <BadgeTitle>{badge.title}</BadgeTitle>

                {badge.level &&
                    <LevelText>{translate('badges.level' + badge.level) ? translate('badges.level' + badge.level) : badge.level}</LevelText>
                }

                <BadgeDescription>{badge.available ? badge.availableDescription : badge.description}</BadgeDescription>


                {badge.isOngoing &&
                    <ReStyleProgress>
                        <OngoingAchievementProgress achievement={badge}/>
                    </ReStyleProgress>
                }


                {((badge.isChallenge) && (badge.allCorrect)) &&
                    <QuizMasterDescription>{translate('profile.badges.quizmaster-earned')}</QuizMasterDescription>

                }
                {((badge.isChallenge) && (!badge.allCorrect)) &&
                    <QuizMasterDescription>{translate('profile.badges.quizmaster-available')}</QuizMasterDescription>

                }
            </ComponentWrapper>
        </AbsoluteWrapper>
    )
};
