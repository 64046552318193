import {useCallback, useEffect, useLayoutEffect, useMemo, useState} from 'react'

import {checkCondition} from '../../../utils/check-condition'
import {
  Answers,
  Option,
  Views,
} from '../../../api/onboarding/types/onboarding-views'
import {useAnalyticsLogEvent} from '../../../utils/use-analytics'

interface Answer {
  option: Option
  action: 'add' | 'remove'
}

function useQuestionnaire(
  views: Views,
  submitHandler: (cleanAnswers: Answers, answers: Answers) => any,
  startIndex = 0,
  initialAnswers?: Answers,
) {
  const logEvent = useAnalyticsLogEvent()
  const [viewIndex, setViewIndex] = useState(startIndex ?? 0)
  const [direction, setDirection] = useState<'back' | 'forward'>('forward')
  const [isDone, setIsDone] = useState(false)
  const [answers, setAnswers] = useState<Answers>(
    () => initialAnswers ?? buildAnswersObject(views),
  )

  const currentView = views[viewIndex]
  const {path} = currentView
  const category = path[0]
  const hasMore = viewIndex < views.length - 1
  const hasPrev = viewIndex > 0

  const getFinalAnswers = useCallback(() => {
    const copyAnswers = JSON.parse(JSON.stringify(answers))
    for (const view of views) {
      if (view.condition && !checkCondition(view.condition, copyAnswers)) {
        const {path} = view
        const category = path[0]
        delete copyAnswers[category][path[1]]
      }
    }
    return copyAnswers
  }, [answers, views])

  const categories = useMemo(
    () => [...new Set(views.map(view => view.path[0]))],
    [views],
  )

  const nextCategory = useMemo(() => {
    const currentCategoryIndex = categories.indexOf(category)
    return categories[currentCategoryIndex + 1]
  }, [categories, category])

  const answerCurrent = ({option, action = 'add'}: Answer) => {
    const answersCopy = JSON.parse(JSON.stringify(answers))
    if (currentView.type === 'single') {
      answersCopy[path[0]][path[1]] = action === 'add' ? option.value : null
    } else if (currentView.type === 'multiple') {
      answersCopy[path[0]][path[1]][option.value as string | number] =
        action === 'add'
    } else if (currentView.type === 'count') {
      answersCopy[path[0]][path[1]][option.value as string | number] +=
        action === 'add' ? 1 : -1
    }
    logEvent('question-' + currentView.path.join('-'), {
      option: answersCopy[path[0]][path[1]],
    })
    setAnswers(answersCopy)
  }

  const getProgress = (padding = 1) => {
    const numberOfCategories = categories.length
    return (
      ((categories.indexOf(category) + padding) /
        (numberOfCategories + padding)) *
      100
    )
  }

  const getProgressForCategory = (type: string | number, padding = 1) => {
    const index = categories.findIndex(category => category === type)
    if (index == -1) {
      throw Error('Type not found')
    }
    return ((index + padding) / (categories.length + padding)) * 100
  }

  const getValueForOption = (option: Option): number => {
    if (currentView.type === 'single') {
      return answers[path[0]][path[1]] === option.value ? 1 : 0
    }
    if (currentView.type === 'multiple') {
      return answers[path[0]][path[1]][option.value as string | number] ? 1 : 0
    }
    if (currentView.type === 'count') {
      return answers[path[0]][path[1]][option.value as string | number]
    }

    throw Error(`View type ${currentView.type} needs to be handled`)
  }

  const nextView = useCallback(() => {
    setDirection('forward')
    const nextIndex = viewIndex + 1
    if (nextIndex === views.length) {
      setIsDone(true)
    }
    setViewIndex(Math.min(nextIndex, views.length - 1))
  }, [viewIndex, views.length])

  const prevView = useCallback(() => {
    setDirection('back')
    setViewIndex(Math.max(viewIndex - 1, 0))
  }, [viewIndex])

  useLayoutEffect(() => {
    if (
      currentView.condition &&
      !checkCondition(currentView.condition, answers)
    ) {
      direction === 'forward' ? nextView() : prevView()
    }
  }, [answers, currentView, direction, nextView, prevView])

  useEffect(() => {
    if (isDone) {
      const finalAnswers = getFinalAnswers()
      logEvent('submit-result', {finalAnswers})
      submitHandler(getFinalAnswers(), answers)
    }
  }, [answers, getFinalAnswers, isDone, submitHandler])

  return {
    currentView,
    nextView,
    getValueForOption,
    answerCurrent,
    hasPrev,
    hasMore,
    nextCategory,
    getProgress,
    getFinalAnswers,
    prevView,
    category,
    categories,
    getProgressForCategory,
  }
}

function buildAnswersObject(views: Views) {
  const answers: Answers = {}
  for (const view of views) {
    const supportedTypes = ['single', 'multiple', 'count']
    if (!supportedTypes.includes(view.type)) {
      throw new Error(
        `View type "${
          view.type
        }" is not a valid type. Currently supported types are "${supportedTypes.join(
          ' and ',
        )}`,
      )
    }

    const [category, child] = view.path
    answers[category] = answers[category] ?? {}
    answers[category]![child] = view.type === 'single' ? null : {}

    if (view.type === 'multiple') {
      view.options.forEach(({value}) => {
        answers[category]![child][value as string | number] = false
      })
    } else if (view.type === 'count') {
      view.options.forEach(({value}) => {
        answers[category]![child][value as string | number] = 0
      })
    }
  }
  return answers
}

export {useQuestionnaire}
