import {ReactNode, useContext} from 'react'
import baseStyled, {
  ThemeContext,
  ThemedStyledInterface,
  ThemeProvider as StyledThemeProvider,
} from 'styled-components/macro'

import {useConfig} from '../config-context/config-context'
import {Theme} from '../../types/theme/theme'
import {useGetTheme} from './theme-context.helpers'

export const styled = baseStyled as ThemedStyledInterface<Theme>

export const ThemeProvider = ({children}: {children: ReactNode}) => {
  const config = useConfig()
  const {theme} = useGetTheme(config.theme)

  if (!theme) {
    return null
  }

  return <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
}

export function useTheme() {
  const themeContext = useContext<Theme>(ThemeContext)
  if (!themeContext) {
    throw Error('useTheme can only be used within a ThemeContext')
  }
  return themeContext
}
