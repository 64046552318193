import {FootprintResult} from './types/footprint-result'
import {useAuthClient} from '../api-client'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {PostMessage} from '../../types/post-message'
import {useConfig} from '../../context/config-context/config-context'
import {
  Auth,
  ExternalAuth,
  useAuth,
} from '../../context/auth-context/auth-context'
import {useErrorHandler} from 'react-error-boundary'
import {useMemo} from 'react'
import {customPostMessage, fromEntries} from 'utils/helpers'
import {Answers} from '../onboarding/types/onboarding-views'

export const usePostAnswers = () => {
  const queryClient = useQueryClient()
  const client = useAuthClient()
  const config = useConfig()
  const auth = useAuth()
  const endPoint = config.useExternalUserId
    ? `api/users/${(auth as ExternalAuth)?.externalUserId}/footprints/answers`
    : `_ah/api/deeds/v2/footprints/answers`
  const {
    data: footprint,
    mutate: postAnswers,
    error,
    ...result
  } = useMutation(
    ({answers, region}: {answers: Answers; region: string}) =>
      client(endPoint, {
        data: {onboarding: answers, region},
      }),
    {
      onSuccess: async () => {
        const message: PostMessage = {
          source: 'deedsterOnboarding',
          event: 'onboardingCompleted',
        }
        customPostMessage(message, '*')
        await queryClient.removeQueries('footprints/result')
        await queryClient.removeQueries('footprints/consumption')
      },
    },
  )
  useErrorHandler(error)
  return {...result, error, footprint, postAnswers}
}

export const useFootprintResult = () => {
  const client = useAuthClient()
  const config = useConfig()
  const auth = useAuth()
  const endPoint = config.useExternalUserId
    ? `api/users/${(auth as ExternalAuth)?.externalUserId}/footprints/result`
    : `_ah/api/deeds/v2/footprints/result`
  const {
    data: footprintResult,
    error,
    ...result
  } = useQuery<FootprintResult>(
    'footprints/result',
    () =>
      client(endPoint, {
        queryParams: {
          ...(config.language && {language: config.language}),
          comparisons: true,
          svg: true,
        },
      }),
    {
      enabled:
        !config.ignoreResultView &&
        (config.useExternalUserId || !!(auth as Auth).user),
      staleTime: Infinity,
    },
  )

  /* 404 is expected when the user is new **/
  useErrorHandler(((error as any)?.status !== 404 && (error as any)?.status !== 500 && (error as any)?.status !== 401) ? error : null)

  const translations = useMemo(
    () =>
      footprintResult
        ? fromEntries(
            footprintResult.translations as unknown as [string, string][],
          )
        : null,
    [footprintResult],
  )

  return {...result, footprintResult, translations}
}
