import {AppConfig} from '../../types/app-config/app-config'
import {useEffect, useMemo} from 'react'
import {useAsync} from '../../utils/use-async'
import {client} from '../../api/api-client'
import {useErrorHandler} from 'react-error-boundary'
import useQueryParam from '../../utils/use-query-param'
import {AppConfigWithAPIKey} from './config-context'
import {apiKeyToConfig} from '../../types/app-config/api-key-to-config'

function getCookie(name: string): string {
    const nameLenPlus = (name.length + 1);
    return document.cookie
        .split(';')
        .map(c => c.trim())
        .filter(cookie => {
            return cookie.substring(0, nameLenPlus) === `${name}=`;
        })
        .map(cookie => {
            return decodeURIComponent(cookie.substring(nameLenPlus));
        })[0];
    }

export const useCheckAuthConfig = (config?: AppConfigWithAPIKey) => {
  useEffect(() => {
    if (!config) {
      return
    }

    const countTruthy = (array: any[]) => array.filter(Boolean).length
    if (
      countTruthy([
        config.useExternalUserId,
        config.authProvider,
        config.anonymousUser,
      ]) !== 1
    ) {
      throw new Error(
        'Exactly one of "useExternalUserId(true)", "authProvider(string)" or "anonymousUser(true) most be used in config" ',
      )
    }
  }, [config])
}

const useCheckValidAppConfig = (
  testConfig: string,
  configId: string | undefined,
  apiKey: string,
) => {
  if (testConfig && configId) {
    throw new Error('Use either a test-config or apiKey, not both')
  }
  if (apiKey && !configId) {
    throw new Error(
      `If valid apiKey, make sure the api-key maps to a config: ${apiKey}.`,
      //api-key-to-config.ts
    )
  }
}


export const useGetAppConfig = () => {
  const [testConfig] = useQueryParam('test-config')
  let [apiKeyQuery] = useQueryParam('apiKey')
  let [tokenQuery] = useQueryParam('token')
  let apiKey = getCookie('apiKey')
  let token = getCookie('token')
  console.log('token=' + token)
  console.log('tokenQuery=' + tokenQuery)

  apiKey =
    apiKey ||
    apiKeyQuery ||
    process.env.REACT_APP_API_KEY ||
    ""

  token =
    token ||
    tokenQuery

  let configId = useMemo(() => apiKeyToConfig(apiKeyQuery), [apiKeyQuery])

  useCheckValidAppConfig(testConfig, configId, apiKeyQuery)

  configId = configId || testConfig || 'default'

  const {
    data: appConfig,
    isError,
    isSuccess: configIsReady,
    run,
  } = useAsync<AppConfig>()
  useEffect(() => {
    if (configId) {
      /** apiURL = '' since it fetch from public folder rather than api */
      run(client(`app-configs/${configId}/config.json`, {apiURL: ''}))
    }
  }, [configId, testConfig, run])
  // useErrorHandler(
  //   isError && {
  //     message: `Config '${configId ?? testConfig}' doesn't exist`,
  //   },
  // )
  if (configIsReady) {
    return {
      appConfig: {
        ...appConfig,
        apiKey,
        token,
        configId,
      },
      configIsReady,
    }
  } else {
    return {appConfig: undefined}
  }
}
