import {createContext, ReactNode, useContext} from 'react'
import useQueryParam from '../../utils/use-query-param'
import {AppConfig} from '../../types/app-config/app-config'
import {useCheckAuthConfig, useGetAppConfig} from './config-context.helper'

export interface AppConfigWithAPIKey extends AppConfig {
  apiKey: string
  token: string
  configId: string
}

const ConfigContext = createContext({} as AppConfigWithAPIKey)
ConfigContext.displayName = 'ConfigContext'

export const ConfigProvider = ({children}: {children: ReactNode}) => {
  const {appConfig} = useGetAppConfig()

  if (appConfig && process.env.NODE_ENV === 'development') {
    console.log('Applied app-config:', appConfig)
  }

  useCheckAuthConfig(appConfig)

  if (!appConfig) {
    return null
  }

  return (
    <ConfigContext.Provider value={appConfig}>
      {children}
    </ConfigContext.Provider>
  )
}

export function useConfig() {
  const context = useContext(ConfigContext)
  if (context === undefined) {
    throw new Error(`useConfig must be used within a ConfigProvider`)
  }
  return context
}
