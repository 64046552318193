import {styled} from '../context/theme-context'

export interface ButtonProps {
  background?: string
  color?: string
  radius?: string
  disabledColor?: string
  disabledBackground?: string
}

export const Button = styled.button<ButtonProps>`
  text-decoration: none;
  padding: 10px 25px;
  min-width: 235px;
  margin: 0 auto;
  border-radius: ${({radius}) => radius ?? '50px'};
  display: inline-block;
  border: none;
  transition: transform 0.4s ease 0s;
  background: ${({background}) => background};
  color: ${({color}) => color};
  font-size: 17px;
  will-change: transform;

  &:hover {
    cursor: pointer;
    transform: scale(1.01);
    color: ${({color}) => color};
    text-decoration: none;
  }

  &:focus {
    outline-offset: 4px;
  }

  &:active {
    transform: scale(0.98);
  }

  &:disabled {
    :hover {
      transform: scale(1);
    }
    cursor: not-allowed;
    color: ${({disabledColor}) => disabledColor ?? '#aaa'};
    background: ${({disabledBackground}) => disabledBackground};
  }
`

export const TextButton = styled.button<{fontSize?: string}>`
  color: ${({color}) => color ?? 'currentColor'};
  text-decoration: underline;
  text-underline-offset: 2px;
  background: transparent;
  border: none;
  font-size: ${p => p.fontSize ?? '15px'};
  margin-top: 10px;
`
