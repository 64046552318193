import {styled} from '../context/theme-context/theme-context'

interface SpacerConfig {
  axis?: 'horizontal' | 'vertical'
  size: number
}

function getHeight({axis, size}: SpacerConfig) {
  return axis === 'horizontal' ? 1 : size
}
function getWidth({axis, size}: SpacerConfig) {
  return axis === 'vertical' ? 1 : size
}
export const Spacer = styled.span`
  display: block;
  width: ${getWidth}px;
  min-width: ${getWidth}px;
  height: ${getHeight}px;
  min-height: ${getHeight}px;
`
