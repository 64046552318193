import {
  ConsumptionProfileView,
  Meta,
  useConsumptionProfile,
  useConsumptionProfileViews,
  usePostConsumptionProfile,
  ViewOption,
} from 'api/consumption-profile'
import {useEffect, useState} from 'react'
import {checkCondition} from '../../../utils/check-condition'
import {Category} from '../onboarding-calculator.helpers.ts/category-related'

export const useFineTune = (initialCategory: Category) => {
  const {
    views,
    isLoading: isViewsLoading,
    isIdle: isViewsIdle,
    isSuccess: isViewSuccess,
  } = useConsumptionProfileViews()

  const {
    profile,
    isLoading: isProfileLoading,
    isFetching: isProfileFetching,
    isIdle: isProfileIdle,
  } = useConsumptionProfile()
  const {
    postConsumptionProfile,
    error: updateError,
    isLoading: isPostLoading,
  } = usePostConsumptionProfile()

  const [hasChanged, setHasChanged] = useState(false)
  const [answers, setAnswers] = useState<Record<any, any>>(null!)
  const [currentView, setCurrentView] = useState<ConsumptionProfileView>(
    (null as unknown) as ConsumptionProfileView,
  )
  const currentCategory = currentView?.category

  useEffect(() => {
    if (views && profile) {
      setAnswers(buildAnswersObject(views, profile))
    }
  }, [profile, views])

  useEffect(() => {
    if (!currentView && views) {
      setCurrentView(
        views.find(view => view.category === initialCategory) ?? views?.[0],
      )
    }
  }, [currentView, initialCategory, views])

  interface Answer {
    path: [string, string]
    type: ViewOption['type']
    value?: any
    meta?: Meta
  }

  const showOption = (option: ViewOption) => {
    if (option.condition) {
      return checkCondition(option.condition, answers)
    }

    return true
  }

  function updateAnswer(
    {path, value, type, meta}: Answer,
    sendUpdateRequest = true,
  ) {
    setHasChanged(true)
    const answersCopy = JSON.parse(JSON.stringify(answers))

    if (type === 'count') {
      const defaultValue = () => meta?.default
      const adjustValue = () =>
        Math.min(
          meta?.max ?? 999999999,
          Math.max(parseInt(value), meta?.min ?? -99999999),
        )
      answersCopy[path[0]][path[1]] =
        value === '' && !sendUpdateRequest
          ? ''
          : isNaN(parseInt(value))
          ? sendUpdateRequest
            ? defaultValue()
            : value
          : adjustValue()
    } else if (type === 'single') {
      answersCopy[path[0]][path[1]!] =
        answersCopy[path[0]][path[1]!] === value ? null : value
    } else {
      answersCopy[path[0]][path[1]!] = !answersCopy[path[0]][path[1]]
    }

    setAnswers(removeNotMatchingConditions(answersCopy))
    if (sendUpdateRequest) {
      postConsumptionProfile({
        profile: removeNotMatchingConditions(answersCopy),
      })
    }
  }

  const removeNotMatchingConditions = (answers: any) => {
    const copyAnswers = JSON.parse(JSON.stringify(answers))
    for (const option of currentView.options) {
      if (option.condition && !checkCondition(option.condition, answers)) {
        if (option.type === 'single') {
          copyAnswers[option.path[0]][option.path[1]!] = null
        } else {
          option.options.forEach(({value}) => {
            copyAnswers[option.path[0]][value] =
              option.type === 'count' ? 0 : false
          })
        }
      }
    }
    return copyAnswers
  }

  return {
    answers,
    currentView,
    currentCategory,
    setCurrentView,
    views,
    profile,
    isPostLoading,
    showOption,
    updateError,
    updateAnswer,
    hasChanged,
    isProfileFetching: isProfileFetching,
    bootstrapIsLoading:
      isViewsLoading || isViewsIdle || isProfileLoading || isProfileIdle,
  }
}

function buildAnswersObject(views: ConsumptionProfileView[], profile: any) {
  const answers: any = {}
  for (const view of views) {
    view.options.forEach(option => {
      answers[option.path[0]] = answers[option.path[0]] ?? {}
      if (option.type === 'count') {
        option.options.forEach(o => {
          answers[option.path[0]][o.value] =
            profile?.[option.path[0]]?.[o.value] == null
              ? o?.meta?.default
              : Math.round(profile[option.path[0]][o.value])
        })
      }
      if (option.type === 'multiple') {
        option.options.forEach(o => {
          answers[option.path[0]][o.value] =
            profile?.[option.path[0]]?.[o.value] ?? o?.meta?.default
        })
      }
      if (option.type === 'single') {
        answers[option.path[0]][option.path[1]] =
          profile?.[option.path[0]]?.[option.path[1]] ?? null
      }
    })
  }

  return answers
}
