import React, {useEffect} from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import {styled, useTheme} from '../../context/theme-context/theme-context'
import {PageWrapper, TextWrapper, Wrapper} from '../../components/page-wrapper'
import {Form, FormGroup, Input, Error} from '../../components/form'
import {Button} from '../../components/button'
import {Spacer} from '../../components/spacer'
import {Theme} from 'types/theme/theme'
import {
  useAnalyticsCurrentScreen,
  useAnalyticsLogEvent,
} from '../../utils/use-analytics'
import {useTranslations} from '../../api/onboarding'
import {useToast} from '../../utils/use-toast'
import {useUpdateEmail} from './use-update-email'

export const UpdateEmail = () => {
  useAnalyticsCurrentScreen('verify-email')
  const {translations} = useTranslations()
  const logEvent = useAnalyticsLogEvent()
  const theme: Theme = useTheme()

  const {ToastContainer, showToast} = useToast()

  const {
    newEmail,
    confirmNewEmail,
    handleNewEmailChange,
    handleConfirmNewEmailChange,
    isSubmitted,
    isUpdateEmailUserSuccess,
    isUpdateEmailUserLoading,
    updateEmailError,
    handleSubmit,
    handleResendEmail,
    isResendEmailLoading,
    isResendEmailSuccess,
    isResendEmailError,
    onUpdateEmailSuccess,
    isEmailSame,
    currentUser,
    history,
  } = useUpdateEmail()

  useEffect(() => {
    if (currentUser.isAnonymous) {
      history.push('/')
    }
  }, [currentUser, currentUser.isAnonymous, history])

  useEffect(() => {
    if(isUpdateEmailUserSuccess) {
      showToast(translations!['change-email-error-success'])
      setTimeout(() => onUpdateEmailSuccess(), 3000)
    }
  }, [isUpdateEmailUserSuccess])

  useEffect(() => {
    if(isResendEmailSuccess) {
      showToast(translations!['change-email-resend-success'])
    }
  }, [isResendEmailSuccess])

  return (
    <PageWrapper background={theme.updateEmail?.backgroundColor!}>
      <ToastContainer variant="success" />
      <Wrapper>
        <TextWrapper
          titleColor={theme.updateEmail?.titleColor}
          subtitleColor={theme.updateEmail?.subtitleColor}
        >
          <h1>{translations!['change-email-title']}</h1>
          {currentUser.email && (
            <p>
              {translations!['change-email-subtitle'].replace(
                "'{email-address}'",
                currentUser.email,
              )}
            </p>
          )}
          {(!currentUser.isAnonymous || !currentUser.emailVerified) && (
              isResendEmailLoading ?
              (<ClipLoader
                size={25}
                color={theme.defaultButton.backgroundColor}
              />) :
              (<ButtonLink onClick={handleResendEmail}>
                {translations!['change-email-resend-verification-link']}
              </ButtonLink>)
          )}
        </TextWrapper>
        {
            isResendEmailError && (
              <Error color={theme.updateEmail?.errorMessageColor}>
                {translations!['change-email-resend-error']}
              </Error>
            )
        }
        <Form onSubmit={handleSubmit}>
          {!isEmailSame && (
            <Error
              color={theme.updateEmail?.errorMessageColor}
              variant="formError"
            >
              {translations!['change-email-error-mismatch']}
            </Error>
          )}
          {updateEmailError && (
            <Error
              color={theme.updateEmail?.errorMessageColor}
              variant="formError"
            >
              {updateEmailError.message || translations!['change-email-error-server']}
            </Error>
          )}
          <FormGroup>
            <Input
              color={theme.updateEmail?.inputTextColor}
              background={theme.updateEmail?.inputBackgroundColor!}
              type="email"
              name="email"
              value={newEmail.text}
              onChange={e => handleNewEmailChange(e.target.value)}
              id="email"
              required
              placeholder={translations!['change-email-new-email-label']}
            />
            {newEmail.error && isSubmitted && (
              <Error color={theme.updateEmail?.errorMessageColor}>
                {newEmail.error}
              </Error>
            )}
          </FormGroup>
          <FormGroup>
            <Input
              color={theme.updateEmail?.inputTextColor}
              background={theme.updateEmail?.inputBackgroundColor!}
              type="email"
              name="confirm-email"
              value={confirmNewEmail.text}
              onChange={e => handleConfirmNewEmailChange(e.target.value)}
              id="confirm-email"
              required
              placeholder={
                translations!['change-email-confirm-new-email-label']
              }
            />
            {confirmNewEmail.error && isSubmitted && (
              <Error color={theme.updateEmail?.errorMessageColor}>
                {confirmNewEmail.error}
              </Error>
            )}
          </FormGroup>
          <Spacer size={20} />
          <Button
            disabled={isUpdateEmailUserSuccess || isUpdateEmailUserLoading}
            color={theme.defaultButton.textColor}
            radius={theme.defaultButton.borderRadius}
            background={theme.defaultButton.backgroundColor}
            type="submit"
          >
            {isUpdateEmailUserLoading ? (
              <ClipLoader
                size={25}
                color={theme.defaultButton.textColor}
              />
            ) : (
              translations!['change-email-confirm-button']
            )}
          </Button>
        </Form>
      </Wrapper>
    </PageWrapper>
  )
}

const ButtonLink = styled.p`
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px !important;
`
