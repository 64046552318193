import {useMediaQuery} from 'react-responsive'

export type Breakpoints = [
  xs: number,
  sm: number,
  md: number,
  lg: number,
  xl: number,
]

const breakpoints: Breakpoints = [400, 620, 960, 1280, 1920]
const [xs, sm, md, lg, xl] = breakpoints
export const breakpoint = {
  xs: `@media (max-width: ${xs}px)`,
  sm: `@media (min-width: ${sm}px)`,
  md: `@media (min-width: ${md}px)`,
  lg: `@media (min-width: ${lg}px)`,
  xl: `@media (min-width: ${xl}px)`,
  mobileLandscape: `@media (max-height: ${720}px) and (orientation: landscape)`,
  lowHeight: `@media (max-height: ${650}px)`,
}

export function useMediaQuerySm() {
  return useMediaQuery({query: `(min-width: ${sm}px)`})
}

export function useMediaQueryMobilLandscape() {
  return useMediaQuery({
    query: ` (max-height: ${720}px) and (orientation: landscape)`,
  })
}
