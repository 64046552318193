import {styled} from '../context/theme-context/theme-context'
import React from 'react'

export function SimpleErrorFallback({error}: {error: any}) {
  return (
    <FullPageErrorWrapper role="alert">
      <p>Uh oh... There's a problem. Try refreshing the app.</p>
      <pre>{error?.message}</pre>
    </FullPageErrorWrapper>
  )
}

const FullPageErrorWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 25px;
  align-items: center;
  justify-content: center;
  text-align: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({}) => 'white'};
  color: #ab0606;
  > * {
    white-space: pre-wrap;
  }
`
