import {useHistory, useLocation} from 'react-router-dom'
import {useCallback} from 'react'

export function useNavigateAndKeepQueryParams() {
  const location = useLocation()
  const history = useHistory()

  const navigateAndKeepQueryParams = useCallback(
    pathname => {
      history.push({pathname, search: location.search})
    },
    [history, location.search],
  )

  return navigateAndKeepQueryParams
}
