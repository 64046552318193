import React from 'react'
import {usePreloadImage} from '../../../utils/preload-images'
import {useScrollLock} from '../../../utils/use-lock-body-scroll'
import {breakpoint} from '../../../style/media-queries'
import {styled, useTheme} from '../../../context/theme-context/theme-context'
import {Button} from '../../../components/button'
import {Logo, PageWrapper} from '../../../components/page-wrapper'

interface TextAndButtonViewProps {
  onClick?: () => void
  title: string
  paragraphs: string[]
  buttonText: string
}

const TextAndButtonView = ({
  onClick,
  title,
  paragraphs,
  buttonText,
}: TextAndButtonViewProps) => {
  const setTargetElement = useScrollLock()
  const theme = useTheme()
  usePreloadImage(theme.intro?.backgroundImage)
  return (
    <PageWrapper
      background={theme.intro?.background}
      backgroundImage={theme.intro?.backgroundImage}
      ref={setTargetElement}
    >

      {theme.intro?.logo &&
          <Logo src={theme.intro?.logo} alt={'logo'}/>
      }

      <TextWrapper>
        <h1>{title}</h1>
        {paragraphs.map((paragraph, i) => (
          <p key={i}>{paragraph}</p>
        ))}

        <Button
            color={theme.defaultButton.textColor}
            background={theme.defaultButton.backgroundColor}
            radius={theme.defaultButton.borderRadius}
            onClick={onClick}
        >
          {buttonText}
        </Button>

      </TextWrapper>


    </PageWrapper>
  )
}


const TextWrapper = styled.div`
  background: ${({theme}) => theme.defaultColors?.backgroundColor}${({theme}) => theme.defaultColors?.backgroundOpacity};
  border-radius: 20px;
  max-width: 500px;
  margin-top: 40px;
  margin-right: 20px;
  margin-left: 20px;
  text-align: center;
  
  h1 {
    color: ${({theme}) => theme.defaultColors?.headingColor};
    font-size: 24px;
    font-weight: 700;
    margin-top: 40px;
    margin-right: 20px;
    margin-left: 20px;
  }
  p {
    color: ${({theme}) => theme.defaultColors?.textColor};
    font-size: 16px;
    font-weight: 400;
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 20px;
  }
  
  button {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  
  ${breakpoint.sm} {
    h1 {
      font-size: 30px;
    }
    p {
      font-size: 20px;
    }
  }
`



export {TextAndButtonView}
