export const isTouchDevice = () =>
  matchMedia('(hover: none), (pointer: coarse)').matches

export const moveCaretAtEnd = ({target}: {target: HTMLInputElement}) => {
  const tempValue = target.value
  target.value = ''
  target.value = tempValue
}

export function customPostMessage(message: any, targetOrigin: string) {
  // @ts-ignore
  if (window.ReactNativeWebView) {
    // @ts-ignore
    window.ReactNativeWebView.postMessage(JSON.stringify(message))
  } else {
    window.parent.postMessage(message, targetOrigin)
  }
}

export function fromEntries(iterable: [string, string | number][]) {
  return [...iterable].reduce(
    (obj: Record<string, string | number>, [key, val]) => {
      obj[key] = val
      return obj
    },
    {},
  )
}
