import React, {ComponentProps} from 'react'
import {IconButton} from './icon-button'
import {MdKeyboardArrowLeft} from 'react-icons/md'
import {styled, useTheme} from '../context/theme-context/theme-context'
import {breakpoint} from '../style/media-queries'

export const FixedBackButton = (
  props: ComponentProps<typeof IconButton> & {hiddenButClickable?: boolean},
) => {
  const theme = useTheme()
  return (
    <FixedBackButtonWrapper
      backgroundColor="#00000044"
      {...props}
      style={{opacity: props.hiddenButClickable ? 0 : 1}}
    >
      <MdKeyboardArrowLeft size={30} color={theme.backButtonColor} />
    </FixedBackButtonWrapper>
  )
}

export const FixedBackButtonWrapper = styled(IconButton)`
  position: fixed;
  top: 23px;
  left: 15px;
  z-index: 99999;
  height: 30px;
  width: 30px;

  ${breakpoint.sm} {
    height: 40px;
    width: 40px;
    top: 18px;
  }
`
