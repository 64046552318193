import React, {useState} from 'react'
import {breakpoint} from '../../../style/media-queries'
import {useSwipeable} from 'react-swipeable'

import {isTouchDevice} from '../../../utils/helpers'
import {styled} from 'context/theme-context/theme-context'
import {ReactComponent as ArrowLeft} from 'assets/icons/arrow-left.svg'
import {ReactComponent as ArrowRight} from 'assets/icons/arrow-right.svg'
import {css} from 'styled-components'
import {Category} from '../onboarding-calculator.helpers.ts/category-related'
import {FootprintResult} from '../../../api/footprint'

interface CarouselProps {
  footprintResult: FootprintResult
  onCategoryClick: (category: Category) => void
}

const FootprintCarousel = ({
  footprintResult,
  onCategoryClick,
}: CarouselProps) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [action, setAction] = useState<'next' | 'prev' | null>(null)
  const {comparisons, main} = footprintResult

  const handleSvgClick = (e: any) => {
    // id===footprint-[category]
    const id = e.target.parentElement.id
    const category = id.replace('footprint-', '')
    if (category) {
      onCategoryClick(category as Category)
    }
  }

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => nextComparison(),
    onSwipedRight: () => prevComparison(),
  })

  const getNextComparisonColor = () => {
    if (currentIndex < comparisons.length - 1) {
      return comparisons[currentIndex + 1]?.color
    }
  }

  const getPrevComparisonColor = () => {
    if (currentIndex > 0) {
      return comparisons[currentIndex - 1]?.color
    }
  }

  const nextComparison = () => {
    if (currentIndex < comparisons.length - 1) {
      setCurrentIndex(currentIndex + 1)
    }
  }
  const prevComparison = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1)
    }
  }
  //
  return (
    <Wrapper {...swipeHandlers}>
      <FixedTop />
      {comparisons?.map((comparison, index) => {
        const status =
          index === currentIndex
            ? 'current'
            : index === currentIndex - 1
            ? 'prev'
            : index === currentIndex + 1
            ? 'next'
            : 'hidden'
        return (
          <ItemWrapper key={comparison.id} status={status}>
            <TextAndCircle
              color={comparison.color}
              action={action}
              status={status}
            >
              <p>
                {' '}
                {comparison.name}
                <br />
                {(comparison.footprint.total / 1000).toFixed(1)}{' '}
                {footprintResult.displayUnit}
              </p>
            </TextAndCircle>
            <ComparisonWrapper
              action={action}
              status={status}
              dangerouslySetInnerHTML={{__html: comparison.svg}}
            />
          </ItemWrapper>
        )
      })}
      <ItemWrapper status={'current'}>
        <Footprint
          onClick={handleSvgClick}
          dangerouslySetInnerHTML={{__html: main.svg}}
        />
      </ItemWrapper>

      {currentIndex > 0 && !isTouchDevice() && (
        <CarouselButton
          arrowColor={
            '#00000050' ?? getPrevComparisonColor()
          }
          onClick={() => {
            setCurrentIndex(currentIndex - 1)
            setAction('prev')
          }}
          actionType="prev"
        >
          <ArrowLeft />
        </CarouselButton>
      )}
      {currentIndex < comparisons.length - 1 && !isTouchDevice() && (
        <CarouselButton
          arrowColor={
            '#00000050' ?? getNextComparisonColor()
          }
          onClick={() => {
            setAction('next')
            setCurrentIndex(currentIndex + 1)
          }}
          actionType="next"
        >
          <ArrowRight />
        </CarouselButton>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100vw;
  z-index: 1000;
  height: 100%;
  position: relative;
`

const FixedTop = styled.div`
  height: 75px;
  width: 100vw;
  background: ${({theme}) => theme.footprint!.topBar};
  ${breakpoint.mobileLandscape} {
    height: 55px;
  }
`
const CarouselButton = styled.button<{
  actionType: 'prev' | 'next'
  arrowColor?: string | undefined
}>`
  position: absolute;
  background: transparent;
  border: none;
  display: none;
  left: ${({actionType}) => (actionType === 'prev' ? '15%' : '85%')};
  transform: translateX(-50%);
  :hover > * {
    transition: all 0.4s;
    transform: scale(1.05);
  }
  :active > * {
    transition: all 0.4s;
    transform: scale(0.95);
  }

  ${breakpoint.sm} {
    display: inline-block;
    top: 38vh;
  }
  ${breakpoint.mobileLandscape} {
    display: inline-block;
    top: 38vh;
  }

  > * > * {
    fill: ${p => p.arrowColor};
  }
`

const animationTime = 0.6
const ItemWrapper = styled.div<{
  status: 'prev' | 'current' | 'next' | 'hidden'
}>`
  transition: all ${animationTime}s ease-out;
  position: absolute;
  display: ${({status}) => (status === 'hidden' ? 'none' : 'inline-block')};
  width: 200px;
  ${breakpoint.sm} {
    width: 400px;
  }
  height: 100%;
  transform: translateX(-50%);
  left: ${({status}) =>
    status === 'current' ? '50%' : status === 'prev' ? 0 : '100%'};
`

const TextAndCircle = styled.div<{
  status?: 'prev' | 'current' | 'next' | 'hidden'
  action: 'next' | 'prev' | null
  color: string
}>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  color: ${({theme}) => theme.footprint!.topBarText};
  width: 15vw;
  left: 50%;
  transform: translate(-50%, 0);

  > p {
    position: absolute;
    text-align: center;
    height: 50px;
    width: 200px;
    margin: 0;
    font-size: 10px;
    line-height: 1.3;
    padding: 0;
    :first-child {
      transform: translateY(-55%);
    }

    ${breakpoint.sm} {
      font-size: 12px;
    }

    ${breakpoint.mobileLandscape} {
      :first-child {
        transform: translateY(-40%);
      }
      z-index: 10;
    }
  }

  ::after {
    transform: translateX(-50%) translateY(0);
    left: 50%;
    position: absolute;
    border-radius: 50%;
    background: ${({color}) => color};
    content: '';
    width: 35px;
    height: 35px;
    ${breakpoint.mobileLandscape} {
      width: 30px;
      height: 30px;
    }
  }
`

const Footprint = styled.div`
  position: absolute;
  z-index: 100;
  left: 50%;
  top: 0;
  transform: translateX(-50%);

  > svg {
    z-index: 1000;
    position: relative !important;

    width: 100vw !important;
    height: 60vh !important;
    transform: scale(1.3);

    ${breakpoint.sm} {
      transform: scale(1.2);
      height: 60vh !important;
    }

    ${breakpoint.mobileLandscape} {
      transform: scale(1.3);
      height: 60vh !important;
    }

    path {
      pointer-events: none;
    }

    [id^='footprint']:hover {
      cursor: pointer;
    }
    #footprint-circle:hover {
      cursor: default;
    }

    #footprint-circle > :nth-child(2) {
      cursor: default;
    }
  }
`

const ComparisonWrapper = styled(Footprint)<{
  status?: 'prev' | 'current' | 'next' | 'hidden'
  action: 'next' | 'prev' | null
}>`

 ${({status}) => {
   if (status !== 'current') {
     return css`
       top: 0;
     `
   }
 }}
  
  > svg {
    transition: all ${animationTime}s ease-out;
    ${({status}) => {
      if (status !== 'current') {
        return css`
          width: 0 !important;
          height: 0 !important;
        `
      }
    }};
`

export {FootprintCarousel}
