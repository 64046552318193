import {useQuestionnaire} from './use-questionnaire'

import React, {useEffect} from 'react'
import styled from 'styled-components/macro'
import {CategoryProgressBar} from './category-progress-bar'
import {useTheme} from '../../../context/theme-context'

import {breakpoint} from '../../../style/media-queries'
import {usePreloadImage} from '../../../utils/preload-images'
import {useTranslations} from '../../../api/onboarding'
import {FixedBackButton} from '../../../components/fixed-back-button'
import {CircleButton, CountButton} from './circle-button'
import {Button} from '../../../components/button'
import {PageWrapper} from '../../../components/page-wrapper'
import {Answers, Views} from '../../../api/onboarding/types/onboarding-views'
import {css} from 'styled-components'
import {useAnalyticsCurrentScreen} from '../../../utils/use-analytics'

interface QuestionnaireProps {
  startIndex?: number
  onSubmit: (cleanAnswers: Answers, answers: Answers) => void
  showBackButtonOnFirstQuestion: boolean
  onBackOnFirstView: () => void
  views: Views
  initialAnswers?: Answers
}

const QuestionnaireStep = ({
  startIndex = 0,
  onSubmit,
  showBackButtonOnFirstQuestion = true,
  onBackOnFirstView,
  views,
  initialAnswers,
}: QuestionnaireProps) => {
  useAnalyticsCurrentScreen('questionnaire')
  const {
    currentView,
    category,
    nextCategory,
    hasPrev,
    getValueForOption,
    nextView,
    prevView,
    getProgress,
    answerCurrent,
    getProgressForCategory,
    categories,
    hasMore,
  } = useQuestionnaire(views, onSubmit, startIndex, initialAnswers)
  const theme = useTheme()
  const {translations} = useTranslations()

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
  }, [currentView])
  usePreloadImage(theme.questionnaire[nextCategory]?.backgroundImage)

  return (
    <PageWrapper
      background={theme.questionnaire[category].background}
      backgroundImage={theme.questionnaire[category]?.backgroundImage}
    >
      {(showBackButtonOnFirstQuestion || hasPrev) && (
        <FixedBackButton
          color={theme.backButtonColor}
          onClick={() => (hasPrev ? prevView() : onBackOnFirstView())}
        />
      )}
      <FixedTop background={theme.questionnaire[category].background}>
        <CategoryProgressBar
          barColor={theme.questionnaire.progressBarColor}
          iconColor={theme.questionnaire.progressIconColor}
          currentProgress={getProgress()}
          categoriesInfos={categories.map(c => ({
            type: c,
            progressStep: getProgressForCategory(c),
          }))}
        />
      </FixedTop>
      <Question color={theme.questionnaire[category].questionColor}>
        {currentView.title}
      </Question>
      <AnswersWrapper
        hasContinueButton={
          currentView.type === 'multiple' || currentView.type === 'count'
        }
        numberOfOptions={currentView.options.length}
      >
        {currentView.options.map((option, index) => {
          if (
            currentView.type === 'single' ||
            currentView.type === 'multiple'
          ) {
            const isSelected = !!getValueForOption(option)
            return (
              <AnswerWrapper
                index={index}
                key={option.label ?? option.value.toString()}
              >
                <CircleButton
                  isSelected={isSelected}
                  border={theme.questionnaire.selectedBackground}
                  background={
                    isSelected
                      ? theme.questionnaire.selectedBackground
                      : theme.questionnaire[category].optionBackground
                  }
                  color={
                    isSelected
                      ? theme.questionnaire.selectedText
                      : theme.questionnaire[category].optionText
                  }
                  onClick={() => {
                    if (currentView.type === 'multiple') {
                      answerCurrent({
                        option,
                        action: isSelected ? 'remove' : 'add',
                      })
                    } else if (currentView.type === 'single') {
                      answerCurrent({
                        option,
                        action: 'add',
                      })
                      if (hasMore) {
                        setTimeout(() => nextView(), 400)
                      }
                    }
                  }}
                >
                  <span>{option.label ?? option.value.toString()}</span>
                </CircleButton>
              </AnswerWrapper>
            )
          } else if (currentView.type === 'count') {
            const count = getValueForOption(option)
            return (
              <AnswerWrapper
                index={index}
                key={option.label ?? option.value.toString()}
              >
                <CountButton
                  border={theme.questionnaire.selectedBackground}
                  background={theme.questionnaire[category].optionBackground}
                  color={theme.questionnaire[category].optionText}
                  add={() => answerCurrent({option, action: 'add'})}
                  remove={() => answerCurrent({option, action: 'remove'})}
                  count={count}
                >
                  {option.label ?? option.value.toString()}
                </CountButton>
              </AnswerWrapper>
            )
          }
        })}
      </AnswersWrapper>
      <BottomPart>
        {currentView.type !== 'single' || !hasMore ? (
          <Button
            radius={theme.defaultButton.borderRadius}
            background={theme.questionnaire.nextButton.background}
            color={theme.questionnaire.nextButton.color}
            onClick={nextView}
          >
            {hasMore
              ? translations!['button-continue']
              : translations!['onboarding-last-button']}
          </Button>
        ) : null}
      </BottomPart>
    </PageWrapper>
  )
}

const FixedTop = styled.div<{background: string}>`
  position: fixed;
  padding: 0;
  padding-bottom: 20px;
  width: 100%;
  top: 0;
  z-index: 2;
  //Remove this in order to remove fading on questions
  background: ${p =>
    css` linear-gradient(180deg,
    ${p.background + 'f7'} 0%,
    ${p.background + '00'} 100%)`};
`

const Question = styled.h2<{color: string}>`
  margin: 75px 0 20px 0;
  font-size: 18px;

  max-width: 320px;
  padding: 0 25px;
  color: ${({color}) => color};
  font-weight: 700;
  line-height: 1.3;
  text-align: center;

  ${breakpoint.sm} {
    font-size: 24px;
    max-width: 500px;
    margin-top: 90px;
  }
  ${breakpoint.mobileLandscape} {
    font-size: 20px;
  }
`

function getMobilePosition(index: number) {
  const positions = [
    {
      top: 0,
      right: '-17px',
    },
    {
      top: 98,
      left: '-2px',
    },
    {
      top: 156,
      right: '-2px',
    },
    {
      top: 248,
      left: '-17px',
    },
  ]

  const offset = Math.floor(index / positions.length)
  const position = positions[index % positions.length]
  const realPosition = {
    ...position,
    top: position.top + offset * 355 + 'px',
  }

  return realPosition
}

const AnswerWrapper = styled.div<{index: number}>`
  position: absolute;
  ${({index}) => getMobilePosition(index)}

  ${breakpoint.sm} {
    position: static;
    margin: 0 7px;
    margin-bottom: 30px;
  }

  ${breakpoint.mobileLandscape} {
    position: static;
    margin: 0 7px;
    margin-bottom: 20px;
  }
`
const AnswersWrapper = styled.div<{
  hasContinueButton: boolean
  numberOfOptions: number
}>`
  height: ${({numberOfOptions}) => numberOfOptions * 90}px;
  position: relative;
  display: flex;
  max-width: 350px;
  width: 0;
  justify-content: center;
  margin-bottom: ${p => (p.hasContinueButton ? '150px' : '80px')};
  flex-wrap: wrap;

  ${breakpoint.sm} {
    height: 100%;
    width: 100%;
    max-width: 700px;
  }

  ${breakpoint.mobileLandscape} {
    height: 100%;
    width: 100%;
    margin-bottom: ${p => (p.hasContinueButton ? '100px' : '50px')};
    max-width: 600px;
  }
`

const BottomPart = styled.div`
  position: fixed;
  bottom: 30px;
  z-index: 100;

  ${breakpoint.sm} {
    bottom: 6vh;
  }

  ${breakpoint.mobileLandscape} {
    bottom: 8vh;
  }
`

export {QuestionnaireStep}
