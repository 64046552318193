import {styled} from '../../../context/theme-context/theme-context'
import {breakpoint} from '../../../style/media-queries'
import ColorManipulate from 'color'
import React from 'react'
import {ButtonProps} from '../../../components/button'

interface CircleButtonProps extends ButtonProps {
  scaleOnClick?: boolean
  border?: string
  isSelected?: boolean
}

export const CircleButton = styled.button<CircleButtonProps>`
  text-decoration: none;
  border-radius: 50%;
  height: 144px;
  letter-spacing: 1.2px;
  width: 144px;
  font-size: 12px;
  word-break: break-word;
  will-change: transform;
  transition: transform 0.5s;
  will-change: transform;

  ${breakpoint.sm} {
    height: 160px;
    width: 160px;
    font-size: 13px;
  }

  ${breakpoint.mobileLandscape} {
    height: 110px;
    width: 110px;
    font-size: 12px;
  }

  display: inline-block;
  border: ${({border}) => `3px solid ${border}`};
  background: ${({background}) => background};
  color: ${({color}) => color};

  :active {
    transition: all 0.5s ease;
    background-color: ${({background, isSelected}) =>
      !isSelected ? ColorManipulate(background).darken(0.2).hex() : background};
    transform: ${({scaleOnClick}) => (scaleOnClick ? 'scale(0.95)' : '')};
  }
  &:hover {
    cursor: pointer;
  }

  ${breakpoint.mobileLandscape} {
    height: 130px;
    width: 130px;
    font-size: 12px;
  }
`
CircleButton.defaultProps = {
  scaleOnClick: true,
}

/** CountButton */
interface CountButtonProps extends CircleButtonProps {
  children: React.ReactNode
  add: () => void
  remove: () => void
  count: number
}

export function CountButton({
  children: label,
  add,
  remove,
  count,
  ...props
}: CountButtonProps) {
  return (
    <CountButtonWrapper>
      <CircleButton {...props} scaleOnClick={false} onClick={add}>
        <CircleButtonContent>
          <h3 className="count">{count}</h3>
          <p className="label">{label}</p>
        </CircleButtonContent>
      </CircleButton>{' '}
      {count ? <RemoveButton onClick={remove} /> : null}
    </CountButtonWrapper>
  )
}

const CountButtonWrapper = styled.div`
  position: relative;
  :active {
    transition: transform 0.5s ease;
    outline: none;
    transform: scale(0.95);
  }
`

const CircleButtonContent = styled.div`
  .count {
    margin: 0;
    font-weight: bold;
    font-size: 26px;
  }
  .label {
    font-size: 10px;
    margin: 0;
  }
`

const RemoveButton = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0;
  background: white;
  border: none;
  transform: translate(10px, 0px);
  border-radius: 50%;
  height: 35px;
  background: ${({theme}) => theme.questionnaire.removeCountButtonBackground};
  width: 35px;
  will-change: transform;

  ${breakpoint.sm} {
    transform: translate(10px, 5px);
    height: 35px;
    width: 35px;
  }
  ${breakpoint.mobileLandscape} {
    height: 30px;
    width: 30px;
    transform: translate(10px, 0);
  }
  :before {
    content: '-';
    color: ${({theme}) => theme.questionnaire.removeCountButtonColor};
    position: absolute;
    top: 50%;
    font-size: 15px;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  :active {
    background: ${({theme}) =>
      ColorManipulate(theme.questionnaire.removeCountButtonBackground)
        .darken(0.05)
        .hex()};
  }
`
