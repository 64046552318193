import {TextAndButtonView} from './text-and-button-view'
import {Auth, useAuth} from '../../../context/auth-context'
import React from 'react'
import {useTranslations} from '../../../api/onboarding'
import {useAnalyticsCurrentScreen} from '../../../utils/use-analytics'

const SingleSignOnIntro = () => {
  useAnalyticsCurrentScreen('single-sign-on-intro')
  const auth = useAuth()
  const {translations} = useTranslations()
  return (
    <TextAndButtonView
      onClick={(auth as Auth).signIn}
      title={translations!['login-header']}
      paragraphs={[translations!['login-text']]}
      buttonText={translations!['login-button']}
    />
  )
}

export {SingleSignOnIntro}
