import {styled} from '../context/theme-context/theme-context'
import React, {
  InputHTMLAttributes,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react'
import {moveCaretAtEnd} from '../utils/helpers'
import {BsEye, BsEyeSlash} from 'react-icons/bs'
import {BiErrorCircle} from 'react-icons/bi'

import {css} from 'styled-components'
import {IconButton} from './icon-button'

export const Form = styled.form`
  max-width: 400px;
  width: 100%;
  display: flex;
  padding: 20px;
  justify-content: center;
  flex-direction: column;
  align-items: stretch;
`
export const Input = styled.input<{background?: string}>`
  border-radius: 4px;
  background: ${p => p.background};
  color: ${p => p.color};
  width: 100%;
  padding: 14px 14px;
  border: none;
  font-size: 15px;
  :focus {
    outline: none;
  }
`
export const FormGroup = styled.div`
  margin-bottom: 20px;

  div {
    display: flex;
    align-items: center;
  }
`

export const PasswordInput = ({
  onChange,
  placeholder,
  passwordIconColor,
  color,
  background,
}: {
  error?: string
  background?: string
  passwordIconColor?: string
} & InputHTMLAttributes<HTMLInputElement>) => {
  const passwordRef = useRef<HTMLInputElement>(null)
  const [showPassword, setShowPassword] = useState(false)
  const firstUpdate = useRef(true)
  const toggleShow = () => {
    setShowPassword(!showPassword)
    passwordRef?.current?.focus()
  }

  useEffect(() => {
    //Focusing on end of input after show password is toggled
    if (passwordRef.current && !firstUpdate.current) {
      const password: string = passwordRef.current.value
      passwordRef.current.setSelectionRange(password.length, password.length)
    }
    firstUpdate.current = false
  }, [showPassword])

  return (
    <PasswordInputWrapper>
      <div>
        <Input
          color={color}
          background={background}
          ref={passwordRef}
          onFocus={moveCaretAtEnd}
          type={showPassword ? 'text' : 'password'}
          onChange={onChange}
          placeholder={placeholder}
        />
        <IconButton
          style={{position: 'absolute', right: 2}}
          onClick={() => {
            setShowPassword(!showPassword)
            ;(passwordRef?.current as any)?.focus()
          }}
        >
          {showPassword ? (
            <BsEyeSlash
              onClick={toggleShow}
              size={25}
              color={passwordIconColor}
            />
          ) : (
            <BsEye onClick={toggleShow} size={25} color={passwordIconColor} />
          )}
        </IconButton>
      </div>
    </PasswordInputWrapper>
  )
}

const PasswordInputWrapper = styled.div`
  width: 100%;
  & div {
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
  }
`

type ErrorVariant = 'fieldError' | 'formError'
export const Error = ({
  children,
  variant = 'fieldError',
  color,
}: {
  children: ReactNode
  variant?: ErrorVariant
  color?: string
}) => {
  return (
    <ErrorWrapper color={color} variant={variant} role="alert">
      <BiErrorCircle size={variant === 'fieldError' ? 22 : 40} />
      <p>{children}</p>
    </ErrorWrapper>
  )
}

const errorVariants = {
  fieldError: css`
    font-size: 12px;
    margin-bottom: 0px;
  `,
  formError: css`
    font-size: 16px;
    margin-bottom: 15px;
  `,
}

export const ErrorWrapper = styled.div.attrs({role: 'alert'})<{
  variant: ErrorVariant
  color?: string
}>`
  display: flex;
  align-items: center;
  color: ${p => p.color ?? 'red'};
  margin-left: 5px;
  margin-top: 4px;
  ${p => errorVariants[p.variant]}
  p {
    margin: 0;
    margin-left: 5px;
  }
`
