import {TextAndButtonView} from './text-and-button-view'
import {useAuth} from '../../../context/auth-context'
import React from 'react'
import {useTranslations} from '../../../api/onboarding'
import {useAnalyticsCurrentScreen} from '../../../utils/use-analytics'

const IntroStep = ({next}: {next: () => void}) => {
  useAnalyticsCurrentScreen('intro')
  const auth = useAuth()
  const {translations} = useTranslations()
  return (
    <TextAndButtonView
      onClick={next}
      title={translations!['intro-header']}
      paragraphs={[translations!['intro-text']]}
      buttonText={translations!['intro-button']}
    />
  )
}

export {IntroStep}
