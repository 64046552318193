import {useScrollLock} from '../utils/use-lock-body-scroll'
import {styled, useTheme} from '../context/theme-context/theme-context'
import React, {useEffect, useState} from 'react'
import {breakpoint} from '../style/media-queries'
import {Spacer} from './spacer'

export function FullPageLoader({
  backgroundColor,
  delaySpinner = 0,
}: {
  backgroundColor?: string
  delaySpinner?: number
  showLoadingText?: boolean
}) {
  const setTargetElement = useScrollLock()
  const theme = useTheme()
  const [showSpinner, setShowSpinner] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => setShowSpinner(true), delaySpinner)
    return () => clearTimeout(timeout)
  })

  return (
    <FullPageLoaderWrapper
      backgroundColor={backgroundColor}
      ref={setTargetElement}
    >
      {showSpinner && (
        <LoaderWrapper>
          <img
            height={50}
            width={50}
            src={theme.fullPageLoading.loadingImgSrc}
            alt="loading..."
          />
          <Spacer size={12} />
        </LoaderWrapper>
      )}
    </FullPageLoaderWrapper>
  )
}

const FullPageLoaderWrapper = styled.div<{backgroundColor?: string}>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 99999;
  background: ${({theme, backgroundColor}) =>
    backgroundColor || theme.fullPageLoading.backgroundColor};

  img {
    height: ${p => p.theme.fullPageLoading.loadingImgHeightMobilePx ?? 50}px;
    width: ${p => p.theme.fullPageLoading.loadingImgWidthMobilePx ?? 50}px;

    ${breakpoint.sm} {
      height: 70px;
      width: 70px;
    }
  }
`

const LoaderWrapper = styled.div`
  text-align: center;
`
