import React from 'react'
import {styled} from '../../../context/theme-context/theme-context'
import {breakpoint} from '../../../style/media-queries'
import {
  Category,
  getIconFromCategory,
} from '../onboarding-calculator.helpers.ts/category-related'

interface CategoriesInfo {
  type: Category
  progressStep: number
}

interface CategoryProgressBarProps {
  currentProgress: number
  iconColor: string
  barColor: string
  categoriesInfos: CategoriesInfo[]
}

const CategoryProgressBar = ({
  currentProgress,
  iconColor,
  barColor,
  categoriesInfos,
}: CategoryProgressBarProps) => {
  return (
    <Wrapper>
      <IconsWrapper>
        {categoriesInfos.map(c => {
          const Icon = getIconFromCategory(c.type)
          return (
            <ProgressIcon
              iconColor={iconColor}
              progressStep={c.progressStep}
              key={c.type}
            >
              <Icon className="icon" />
            </ProgressIcon>
          )
        })}
      </IconsWrapper>
      <ProgressBar progressColor={barColor} progress={currentProgress} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  margin: auto;
  max-width: 200px;

  ${breakpoint.sm} {
    max-width: 400px;
  }

  ${breakpoint.mobileLandscape} {
    max-width: 325px;
  }
`

const IconsWrapper = styled.div`
  transform: translateY(-28px);
  ${breakpoint.sm} {
    transform: translateY(-40px);
  }

  ${breakpoint.mobileLandscape} {
    transform: translateY(-40px);
  }
`

const ProgressIcon = styled.div<{
  iconColor: string
  background?: string | undefined
  progressStep: number
}>`
  
  .icon {
    & path {
      stroke: ${({iconColor}) => iconColor};
    }
    width: 22px;
    height: 22px;
    position: absolute;
    background: ${({background}) => background ?? 'transparent'};
    left: ${({progressStep}) => progressStep + '%'};
    transform: translate(-50%, 0px);
    ${breakpoint.sm} {
      width: 30px;
      height: 30px;
    }
    
    
    ${breakpoint.mobileLandscape} {
      width: 30px;
      height: 30px;
    } 
   
    }
  }
`

/** ProgressBar */
interface ProgressBarProps {
  progress: number
  progressColor?: string
}

const ProgressBar = ({progressColor, progress}: ProgressBarProps) => {
  return (
    <Bar>
      <Progress progressColor={progressColor} progress={progress} />
    </Bar>
  )
}

const Bar = styled.div`
  height: 4px;
  width: 100%;
  background: ${({theme}) => '#ffffff' + '55'};
  border-radius: 50px;
  margin: 55px 0 20px 0;

  ${breakpoint.sm} {
    margin-top: 60px;
    height: 7px;
  }

  ${breakpoint.mobileLandscape} {
    margin-top: 60px;
    height: 4px;
  }
`
const Progress = styled.div<ProgressBarProps>`
  height: 100%;
  transition: width 0.5s ease-in-out;
  width: ${({progress}) => progress}%;
  background: ${({progressColor}) => progressColor};
  border-radius: inherit;
  text-align: right;
`

export {CategoryProgressBar}
