import React from 'react'
import {Country, SelectCountry} from './select-country'
import {breakpoint} from '../../../style/media-queries'
import {usePreloadImage} from '../../../utils/preload-images'
import {styled, useTheme} from '../../../context/theme-context'
import {useCountries, useTranslations} from '../../../api/onboarding'
import {Button} from '../../../components/button'
import {Logo, PageWrapper} from '../../../components/page-wrapper'
import {
  useAnalyticsCurrentScreen,
  useAnalyticsLogEvent,
} from '../../../utils/use-analytics'

interface SelectCountryStepProps {
  next: () => void
  onSelect: (country: Country) => void
  selectedCountry?: Country | undefined
}

function SelectCountryStep({
  next,
  onSelect,
  selectedCountry,
}: SelectCountryStepProps) {
  useAnalyticsCurrentScreen('Select country')
  const logEvent = useAnalyticsLogEvent()
  const theme = useTheme()
  const {translations} = useTranslations()
  const {countries} = useCountries()
  usePreloadImage(theme.questionnaire['food']?.backgroundImage)
  return (
    <PageWrapper
      background={theme.intro?.background}
      backgroundImage={theme.intro?.backgroundImage}
    >

      {theme.intro?.logo &&
          <Logo src={theme.intro?.logo} alt={'logo'}/>
      }
      <TextWrapper>
        <h1>{translations!['country-headline']}</h1>
        <p>{translations!['country-description']}</p>
        <SelectCountry
          countries={countries!}
          placeholder={translations!['country-search-field']}
          selectedCountry={selectedCountry}
          onSelect={onSelect}
        />

        <Button
            color={theme.defaultButton.textColor}
            background={theme.defaultButton.backgroundColor}
            disabledColor={theme.defaultButton.disabledTextColor}
            disabledBackground={theme.defaultButton.disabledBackgroundColor}
            radius={theme.defaultButton.borderRadius}
            disabled={!selectedCountry}
            onClick={() => {
              logEvent('select-country', {selectedCountry})
              next()
            }}
        >
          {translations!['button-continue']}
        </Button>

      </TextWrapper>

    </PageWrapper>
  )
}

const TextWrapper = styled.div`
  background: ${({theme}) => theme.defaultColors?.backgroundColor}${({theme}) => theme.defaultColors?.backgroundOpacity};
  border-radius: 20px;
  width: calc(100% - 40px);
  max-width: 540px;
  margin-top: 40px;
  margin-right: 20px;
  margin-left: 20px;
  text-align: center;

  h1 {
    color: ${({theme}) => theme.defaultColors?.headingColor};
    font-size: 24px;
    font-weight: 700;
    margin-top: 40px;
    margin-right: 20px;
    margin-left: 20px;
  }

  p {
    color: ${({theme}) => theme.defaultColors?.textColor};
    font-size: 16px;
    font-weight: 400;
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 20px;
  }
  
  button {
    margin-bottom: 40px;
    margin-top: 40px;
  }

  ${breakpoint.sm} {
    h1 {
      font-size: 30px;
    }

    p {
      font-size: 20px;
    }
  
`

export {SelectCountryStep}
