import React, {useState} from 'react'
import {Bar, Line} from 'react-chartjs-2'
import {GraphCard, GraphDeed, GraphLabel, GraphSavings, GraphTab, TabItem,} from '../../../components/profile'
import 'chartjs-adapter-moment';
import {translate} from '../../../api/localization'


//Should move to theme by someone who know how -Madeleine
const colorBarPast = '#ffffff'
const colorBarPresent = '#E6A3B7'
const colorBarFuture = '#88B7BB'
const colorGradientTop = 'rgba(255, 255,255, 0.9)'
const colorGradientButtom = 'rgba(255, 255, 255, 0)'
const colorBorder = '#ffffff'
const colorPointBackground = '#E6A3B7'

// function for adding a circle on the last point in the line graphs
function radiusOnLastElement(data) {
    let radius = []
    const length = data.datasets.length
    if (length !== 0) {
        radius = Array(length - 1).fill(0);
    }
    radius.push(5.5);
    return radius;
}

// function for adding a gradient color to the line graphs
let gradientWidth, gradientHeight, gradient;
function getGradient(ctx, chartArea) {
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (gradient === null || gradientWidth !== chartWidth || gradientHeight !== chartHeight) {
        // Create the gradient because this is either the first render
        // or the size of the chart has changed
        gradientWidth = chartWidth;
        gradientHeight = chartHeight;
        gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
        gradient.addColorStop(1, colorGradientTop);
        gradient.addColorStop(0, colorGradientButtom);
    }
    return gradient;
}

const BarGraphDeeds = ({
                           data = {
                               labels: [],
                               datasets: []
                           },
                       }) => {

    // Set bar colors for past, today and future values
    function getBarColors() {
        var passedToday = false
        return data.labels.map(o => {
            if (o === data.today) {
                passedToday = true
                return colorBarPresent
            } else if(passedToday)
                return colorBarFuture
            else
                return colorBarPast
        })
    }

    const config = {
        elements: {
            bar: {
                borderRadius: 5,
                backgroundColor: getBarColors(),
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                displayColors: false,
                backgroundColor: '#88B7BB',
                titleColor: '#FFFFFF',
                bodyColor: '#FFFFFF',
                bodyAlign: 'center',
            }
        },
        scales: {
            xAxis: {
                display: true,
                ticks: {
                    color: getBarColors,
                },
                grid: {
                    display: false,
                },
            },
            yAxis: {
                display: false,
            },
        },
        responsive: true,
    }

    return <Bar data={{
        labels: data.labels,
        datasets: [
            {
                data: data.datasets,
                minBarLength: 10
            }
        ],
    }} options={config}/>
}


const LineGraphDeeds = ({
                            data = {
                                labels: [],
                                datasets: [],
                            },
                            displayXAxis = false
                        }
                        ) => {

    const config = {
        elements: {
            point: {
                pointBackgroundColor: colorPointBackground,
                pointBorderColor: colorBorder,
                pointBorderWidth: 3,
                pointRadius:radiusOnLastElement(data)
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
            }
        },
        scales: {
            xAxis: {
                display: displayXAxis,
                ticks: {
                    color: '#FFFFFF80',
                },
                grid: {
                    display: false,
                },
            },
            yAxis: {
                display: false,
                beginAtZero: true
            },
        },
        responsive: true,
    }


    return <Line data={{
        labels: data.labels,
        datasets: [
            {
                data: data.datasets,
                borderColor: colorBorder,
                fill: true,
                backgroundColor: function(context) {
                    const chart = context.chart;
                    const {ctx, chartArea} = chart;

                    if (!chartArea) {
                        // This case happens on initial chart load
                        return null;
                    }
                    return getGradient(ctx, chartArea);
                },
                cubicInterpolationMode: 'monotone',
                tension: 0.1,
            },
        ],
    }} options={config}/>
}



export const Graph = ({data_thisWeek, data_last30Days, data_last12Months}) => {
    const [graphType, setGraphType] = useState('thisWeek')

    return (
        <GraphCard>
            <GraphTab>
                <TabItem
                    onClick={() => setGraphType('thisWeek')}
                    active={graphType === 'thisWeek'}
                >
                    {translate('profile.statistics.tab.week')}
                </TabItem>
                <TabItem
                    onClick={() => setGraphType('last30Days')}
                    active={graphType === 'last30Days'}
                >
                    {translate('profile.statistics.tab.month')}
                </TabItem>
                <TabItem
                    onClick={() => setGraphType('last12Months')}
                    active={graphType === 'last12Months'}
                >
                    {translate('profile.statistics.tab.year')}
                </TabItem>
            </GraphTab>
            <GraphDeed>
                <div className="deeds-value">
                    {graphType === 'thisWeek' ? (
                        data_thisWeek.total
                    ) : graphType === 'last30Days' ? (
                        data_last30Days.total
                    ) : data_last12Months.total}
                </div>
                <div className="deeds-text"> {translate('profile.deed-count.other')}</div>
            </GraphDeed>
            {graphType === 'thisWeek' ? (
                <BarGraphDeeds data={data_thisWeek}/>
            ) : graphType === 'last30Days' ? (
                <LineGraphDeeds data={data_last30Days}/>
            ) : (
                <LineGraphDeeds data={data_last12Months}/>
            )}

            {graphType === 'thisWeek' ? (
                <GraphLabel></GraphLabel>
            ) : graphType === 'last30Days' ? (
                <GraphLabel>{translate('profile.statistics.last-30-days')}</GraphLabel>
                ) : (
                <GraphLabel>{translate('profile.statistics.last-12-months')}</GraphLabel>
            )}
        </GraphCard>
    )
}


export const GraphTotalReduction = ({allData, co2e}) => {
    return (
        <GraphCard>
            <GraphSavings>
                <div className="savings-text">{translate('profile.statistics.co2.you-saved.headline')}</div>
                <div className="savings-value">
                    {co2e} <span className="savings-sub-text">{translate('profile.statistics.co2.you-saved.kg')}</span>
                </div>
                <div className="savings-sub-text">{translate('profile.statistics.co2.you-saved.co2')}</div>
            </GraphSavings>
            {<LineGraphDeeds data={allData}  displayXAxis={true}/>}
        </GraphCard>
    )
}