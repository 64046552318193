import hash from 'string-hash'

export function apiKeyToConfig(apiKey: string): string | undefined {
  // if (process.env.NODE_ENV === 'development') {
  //   console.log(`apiKey: ${apiKey} === hash ${hash(apiKey)}`)
  // Log it as 'time' to not draw unnecessary attention to it
  console.log('time', hash(apiKey))

  const map: Record<number, string> = {
    2037805849: 'default',
    991769218: 'default', // Prod demo API-key
    1930385759: 'default', // Prod demo API-key, English
    2138670730: 'default', // Prod demo API-key, English
    1809605308: 'default', // Deedster App Dev
    1607530349: 'default', // Deedster App Stage
    4189182184: 'default', // Deedster App Prod
    4220888237: 'default', // NetZeroYou2 App Dev
    1664146148: 'default', // NetZeroYou2 App Stage
    2323974826: 'default', // NetZeroYou2 App Prod
    2404854718: 'default', // SebFootprint App Dev
    4041391006: 'default', // SebFootprint App Stage
    649864623: 'default', // SebFootprint App Prod
    1499516462: 'qliro', // Qliro stage
    3879463807: 'qliro', // Qliro prod
    1747775610: 'spotify-anonymous', // Spotify hack week Stage (anonymous Firebase user)
    1950157154: 'spotify', // Spotify hack week Stage (user id from query parameter)
    1137350379: 'bnp-paribas', // BNP Paribas stage
    396471733: 'bnp-paribas', // BNP Paribas prod
    329463248: 'seb', // SEB prod
    3015972988: 'default', // SEB Employees
    2114389228: 'zurich', // Zurich demo web quiz on prod and stage
    76723456: 'swiss-re',
    28184139: 'swiss-re-prod',
    3056178113: 'deedster-swiss-re', // Test WebCalculator with oidc.sdg12 provider and SwissRe config
    1919771052: 'deedster-swiss-re', // Test WebCalculator with oidc.sdg12 provider and SwissRe config (stage)
    3953586483: 'deedster-swiss-re-anonymous', // Test WebCalculator with anonymous user
    2879469814: 'bnpp-program', // BNP Paribas Program (prod)
    3299472968: 'bnpp-program', // BNP Paribas Program (dev stg)
    1691971535: 'zurich-london-event', // Zurich event Sep 2023 (prd)
  }
  return map[hash(apiKey)]
}