import {createGlobalStyle} from 'styled-components'
import {Theme} from '../types/theme/theme'

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Apercu';
    src: url('https://uploads-ssl.webflow.com/5b07c8f2b3bcf26e8a5414b1/5b07d1d3b3bcf26f91541c27_Apercu-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'Apercu';
    src: url('https://uploads-ssl.webflow.com/5b07c8f2b3bcf26e8a5414b1/5b07d1d3ec7ad71e8e5d889e_Apercu.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Apercu';
    src: url('https://uploads-ssl.webflow.com/5b07c8f2b3bcf26e8a5414b1/5b07d1d316f9267272039167_Apercu-Bold.otf') format('opentype');
    font-weight: 700;
  }
  
  @font-face {
    font-family: 'BNPP';
    src: url('background-images/bnp-paribas/BNPP-Sans.otf') format('opentype');
  }

  @font-face {
    font-family: 'SEB';
    src: url('background-images/seb/SEBSansSerif-Regular.otf') format('opentype');
  }

  html, body, #root {
    height: 100%;
  }

  body {
    letter-spacing: 0.8px;
    line-height: 1.5;
    font-family: ${({theme}) => (theme as Theme).fontFamily};
  }
  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }


  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type=number] {
    -moz-appearance:textfield; /* Firefox */
  }
`

export default GlobalStyles
