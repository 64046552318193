import {styled, useTheme} from '../../../context/theme-context'
import {css} from 'styled-components'
import ClipLoader from 'react-spinners/ClipLoader'
import {useFineTune} from './use-fine-tune'

import {breakpoint, useMediaQuerySm} from '../../../style/media-queries'
import React, {useEffect, useState} from 'react'
import {useInvalidateQueryKey} from '../../../api/api-client'
import {Category} from '../onboarding-calculator.helpers.ts/category-related'
import {isTouchDevice} from '../../../utils/helpers'
import {Checkbox} from '../../../components/checkbox'
import {PageWrapper} from '../../../components/page-wrapper'
import {FixedBackButton} from '../../../components/fixed-back-button'
import {FullPageLoader} from '../../../components/full-page-loader'
import {useToast} from '../../../utils/use-toast'
import {useAnalyticsCurrentScreen} from '../../../utils/use-analytics'
import {useTranslations} from '../../../api/onboarding'

interface FineTuneProps {
  onBackClick: (category: Category) => void
  category: Category
}

const FineTune = ({onBackClick, category}: FineTuneProps) => {
  useAnalyticsCurrentScreen('fine-tune')
  const theme = useTheme()
  const {translations} = useTranslations()
  const invalidateKey = useInvalidateQueryKey()
  const isSmScreen = useMediaQuerySm()
  const [goBackAfterLoading, setGoBackAfterLoading] = useState(false)
  const {ToastContainer, showToast} = useToast()
  const {
    answers,
    currentCategory,
    currentView,
    views,
    setCurrentView,
    isPostLoading,
    showOption,
    updateAnswer,
    bootstrapIsLoading,
    hasChanged,
    updateError,
  } = useFineTune(category)

  useEffect(() => {
    if (goBackAfterLoading && !isPostLoading) {
      if (hasChanged) {
        invalidateKey('footprints/result')
        invalidateKey('footprints/consumption')
      }
      onBackClick(currentCategory!)
    }
  }, [
    category,
    currentCategory,
    goBackAfterLoading,
    hasChanged,
    invalidateKey,
    isPostLoading,
    onBackClick,
  ])

  useEffect(() => {
    if (updateError) {
      showToast(translations!['network-error'])
    }
  }, [showToast, translations, updateError])

  if (bootstrapIsLoading || !answers || !currentCategory) {
    return <FullPageLoader />
  }

  return (
    <PageWrapper background={theme.fineTune?.navBackgroundColor!}>
      {isPostLoading && (
        <ClipLoader
          css={`
            position: fixed;
            left: 15px;
            top: 15px;
            z-index: 99999;
          `}
          size={40}
          color={
            theme.fineTune?.brandingColor ??
            theme.questionnaire[currentCategory]?.background
          }
        />
      )}
      <FixedBackButton
        onClick={() => setGoBackAfterLoading(true)}
        hiddenButClickable={isPostLoading}
      />
      <ToastContainer variant="error" />
      {isSmScreen && <Topbar />}
      <Wrapper>
        {isSmScreen && (
          <SideNav>
            <NavContent>
              {views?.map((view, index) => {
                const {category} = view
                return (
                  <NavButton
                    activeColorContrast={
                      theme.fineTune?.brandingColorContrast ??
                      theme.questionnaire[category as Category]?.optionText
                    }
                    activeColor={
                      theme.fineTune?.brandingColor ??
                      theme.questionnaire[category as Category]
                        ?.optionBackground
                    }
                    key={category ?? index}
                    onClick={() => {
                      setCurrentView(view)
                    }}
                    selected={currentCategory === category}
                  >
                    {view.label}
                  </NavButton>
                )
              })}
            </NavContent>
          </SideNav>
        )}

        <MainContent>
          <ViewHeading>{currentView.label}</ViewHeading>
          {currentView.options.map((option, index) => {
            return (
              showOption(option) && (
                <SectionWrapper key={index}>
                  <SectionHeading>
                    {option.title ?? option.label}
                  </SectionHeading>
                  <SectionDescription>
                      {option.description}
                  </SectionDescription>

              <QuestionWrapper>
                    {option.type === 'single' &&
                      option.options.map(o => (
                        <Option
                          key={o.value}
                          selected={
                            answers[option.path[0]][option.path[1]] === o.value
                          }
                        >
                          <p>{o.label}</p>

                          <Checkbox
                            color={
                              theme.fineTune?.textColor
                            }
                            size={20}
                            onChange={() =>
                              updateAnswer({
                                type: 'single',
                                path: option.path,
                                value: o.value,
                              })
                            }
                            checked={
                              answers[option.path[0]][option.path[1]] ===
                              o.value
                            }
                          />
                        </Option>
                      ))}

                    {option.type === 'multiple' &&
                      option.options.map(o => {
                        return (
                          <Option
                            key={o.value}
                            selected={answers[option.path[0]][o.value]}
                          >
                            <p>{o.label}</p>
                            <Checkbox
                              color={
                                theme.fineTune?.textColor
                              }
                              size={20}
                              key={o.value}
                              checked={answers[option.path[0]][o.value]}
                              onChange={() =>
                                updateAnswer({
                                  type: 'multiple',
                                  path: [option.path[0], o.value],
                                })
                              }
                            />
                          </Option>
                        )
                      })}

                    {option.type === 'count' &&
                      option.options.map((o: any) => {
                        return (
                          <Option
                            clickable={false}
                            key={o.value}
                          >
                            <p>{o.label}</p>
                            <InputAndUnit>
                              <SmallNumberInput
                                onFocus={e => {
                                  if (
                                    e.target.value !== '0' &&
                                    !isTouchDevice()
                                  ) {
                                    e.target.select()
                                  } else {
                                    e.target.value = ''
                                  }
                                }}
                                onBlur={e => {
                                  updateAnswer(
                                    {
                                      type: 'count',
                                      path: [option.path[0], o.value],
                                      value: e.target.value,
                                      meta: o.meta,
                                    },
                                    true,
                                  )
                                }}
                                onChange={e => {
                                  updateAnswer(
                                    {
                                      type: 'count',
                                      path: [option.path[0], o.value],
                                      value: e.target.value,
                                    },
                                    false,
                                  )
                                }}
                                min={o.meta.min}
                                type="number"
                                value={answers[option.path[0]][o.value]}
                              />
                              <span className="unit">{o?.meta?.unit}</span>
                            </InputAndUnit>
                          </Option>
                        )
                      })}
                  </QuestionWrapper>
                </SectionWrapper>
              )
            )
          })}
        </MainContent>
      </Wrapper>
    </PageWrapper>
  )
}

const navWidthSm = 200

const Wrapper = styled.div`
  width: 100%;

  ${breakpoint.sm} {
    display: grid;
    grid-template-columns: ${navWidthSm}px 1fr;
  }
`

const MainContent = styled.div`
  //box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.1);
  background: ${p => p.theme.fineTune?.backgroundColor};
  color: ${p => p.theme.fineTune?.textColor};
  padding: 0 0 100px 0;
  flex: 1;
  ${breakpoint.sm} {
    border-radius: 10px 0 0 0;
    width: 100%;
    padding-right: 0;
    min-height: 100vh;
  }
`

const ViewHeading = styled.h2`
  text-align: left;
  color: ${p => p.theme.fineTune?.textColor};
  width: 100%;
  font-size: 26px;
  font-weight: 700;
  padding-left: 20px;
  padding-top: 80px;

  ${breakpoint.sm} {
    padding-top: 30px;
  }
`

const SectionWrapper = styled.div`
  margin: 10px auto;
`

const SectionHeading = styled.h2`
  color: ${p => p.theme.fineTune?.textColor};
  padding: 0 25px;
  max-width: 600px;
  font-size: 20px;
  text-align: left;
`

const SectionDescription = styled.h5`
  padding: 0 25px;
  max-width: 600px;
  text-align: left;
`

const QuestionWrapper = styled.div`
  display: flex;
  margin-bottom: 25px;
  flex-wrap: wrap;
  padding: 0 20px;
  justify-content: center;
  ${breakpoint.sm} {
    justify-content: stretch;
  }
`

const Topbar = styled.h1<{fontSize?: number}>`
  display: flex;
  width: 100%;
  background: ${p => p.theme.fineTune?.navBackgroundColor};
  height: 50px;
  font-weight: 700;
  margin: 0;
`

const Option = styled.label<{
  selected?: boolean
  clickable?: boolean
}>`
  min-height: 50px;
  hyphens: auto;
  margin: 5px;
  background: ${({selected, theme}) =>
      selected
          ? theme.fineTune?.optionColor
          : theme.fineTune?.optionColor};
  border: ${({selected, theme}) =>
    selected
      ? "2px solid " + theme.fineTune?.textColor
      : ""};
  color:  ${p => p.theme.fineTune?.textColor};
  //box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 8px 10px;
  transition: 0.3s background-color;
  display: flex;
  width: 100%;
  ${({clickable}) => {
    if (clickable) {
      return css`
        cursor: pointer;
        :active {
          background: #ddd;
        }
      `
    }
  }};

  ${breakpoint.sm} {
    max-width: 250px;
  }

  align-items: center;

  p {
    font-weight: ${({selected}) =>
        selected
            ? 700
            : 400};
    display: inline-block;
    margin: 2px;
    margin-right: auto;
    font-size: 14px;
    text-align: left;
    word-break: break-word;
  }

  .unit {
    font-size: 10px;
    color: ${p => p.theme.fineTune?.textColor};
    max-width: 60px;
    overflow: hidden;
  }
`

Option.defaultProps = {
  clickable: true,
}

const SmallNumberInput = styled.input`
  text-align: center;
  color: ${p => p.theme.fineTune?.textColor};
  box-shadow: inset 1px 1px 1px 1px ${p => p.theme.fineTune?.textColor + '22'};
  border-radius: 5px;
  font-size: 18px;
  border: 2px solid transparent;
  outline: #fff;
  width: 60px;
  background: ${p => p.theme.fineTune?.navBackgroundColor};
  will-change: width;

  :focus {
    box-shadow: none;
    border: 2px solid ${p => p.color};
  }
`

const InputAndUnit = styled.div`
  align-items: center;
  margin-left: auto;
  display: flex;
  flex-direction: column;
`

const SideNav = styled.div`
  background: ${p => p.theme.fineTune?.navBackgroundColor};
  color: ${p => p.theme.fineTune?.navTextColor};

  position: relative;
`

const NavContent = styled.nav`
  display: flex;
  width: 100%;
  flex-direction: column;
  position: fixed;

  ${breakpoint.sm} {
    width: ${navWidthSm}px;
    top: 100px;
  }
  
`

export interface NavButtonProps {
  selected?: boolean
  activeColor?: string
  activeColorContrast?: string
}

const NavButton = styled.button<NavButtonProps>`
  background: inherit;
  transition: 0.4s all;
  color: ${p => p.theme.fineTune?.navTextColor};
  font-weight: 700;
  text-align: left;
  border: none;
  font-size: 18px;
  border-radius: 2px;
  padding: 15px 10px;
  ${({selected}) =>
    selected &&
    css`
      color: ${p => p.theme.fineTune?.textColor};
      background: ${p => p.theme.fineTune?.selectedColor};
    `};
  :hover {
    background: ${p => p.theme.fineTune?.selectedColor};
    color:  ${p => p.theme.fineTune?.textColor};
  }
  :focus {
    outline: 3px solid #000000;
  }
`

export {FineTune}
