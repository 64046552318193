import React, {InputHTMLAttributes} from 'react'
import {styled} from '../context/theme-context/theme-context'

type CheckboxProps = Pick<
  InputHTMLAttributes<HTMLInputElement>,
  'checked' | 'onChange'
> &
  CustomStyleProps

interface CustomStyleProps {
  color?: string
  size?: number
  shape?: 'square' | 'round'
}

export const Checkbox = ({
  checked,
  onChange,
  color = 'white',
  size = 30,
  shape = 'round',
  ...props
}: CheckboxProps) => (
  <CheckboxContainer>
    <HiddenCheckbox onChange={onChange} checked={checked} {...props} />
    <StyledCheckbox color={color} size={size} shape={shape} checked={checked!}>
      {checked && <Checkmark size={size} color={color} />}
    </StyledCheckbox>
  </CheckboxContainer>
)

const CheckboxContainer = styled.label`
  display: inline-block;
  cursor: pointer;
  margin: 0;
`

const Checkmark = ({color, size = 24}: {color: string; size: number}) => {
  return (
    <svg viewBox="0 0 24 24" height={size} width={size}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.14995 5.01663L0.699951 6.46663L4.56662 10.3333L13.2666 2.11663L11.8166 0.666626L4.56662 7.43329L2.14995 5.01663Z"
        fill={color}
        transform="translate(5,7)"
      />
    </svg>
  )
}

const HiddenCheckbox = styled.input.attrs({type: 'checkbox'})`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`
const StyledCheckbox = styled.div<CustomStyleProps & {checked: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({size}) => size}px;
  height: ${({size}) => size}px;
  background: transparent;
  border-radius: ${({shape}) => (shape === 'round' ? '50%' : '2px')};
  transition: width 300ms;
  border: 2px solid ${({color}) => color};

  ${HiddenCheckbox}:focus + & {
    //outline: 5px auto -webkit-focus-ring-color;
    //outline-offset: 6px;
  }

  &:hover {
    filter: brightness(85%);
  }
`
