import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxOptionText,
  ComboboxPopover,
} from '@reach/combobox'
import '@reach/combobox/styles.css'
import {ChangeEvent, useMemo, useState} from 'react'
import {matchSorter} from 'match-sorter'
import {styled} from '../../../context/theme-context/theme-context'
import {MdCheckCircle, MdSearch} from 'react-icons/md'
import {useScrollLock} from '../../../utils/use-lock-body-scroll'


interface SelectCountryProps {
  onSelect: (country: Country) => void
  selectedCountry: Country | undefined
  placeholder?: string
  countries: Country[]
}

export interface Country {
  code: string
  name: string
}

function SelectCountry({
  onSelect,
  selectedCountry,
  placeholder,
  countries,
}: SelectCountryProps) {
  const setTargetElement = useScrollLock()

  const [term, setTerm] = useState(selectedCountry?.name ?? '')
  const {matches} = useCountryMatch(term, countries)

  const handleSelect = (name: string) => {
    setTerm(name)
    const country = countries.find(country => country.name === name)!
    onSelect(country)
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleSelect('')
    setTerm(event.target.value)
  }

  return (
    <Wrapper ref={setTargetElement}>
      <StyledCombobox
        onSelect={handleSelect}
        openOnFocus={false}
        aria-label="Select your country"
      >
        <SearchInput
          selected={term && term === selectedCountry?.name}
          placeholder={placeholder ?? ''}
          autoFocus
          value={term}
          onChange={handleChange}
        />

        {
          <Popover portal={true}>
            <List persistSelection>
              {matches.length
                ? matches.slice(0, 20).map(country => (
                    <Option key={country.code} value={country.name}>
                      <ComboboxOptionText />
                    </Option>
                  ))
                : countries.map(country => (
                    <Option key={country.code} value={country.name}>
                      <ComboboxOptionText />
                    </Option>
                  ))}
            </List>
          </Popover>
        }
      </StyledCombobox>
    </Wrapper>
  )
}

const SearchInput = ({selected, ...props}: any) => {
  return (
    <InputWrapper>
      <SearchIcon />
      <StyledInput {...props} />
      {selected && <CheckIcon />}
    </InputWrapper>
  )
}

const Wrapper = styled.div`
  margin: 20px;
`

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px;
  border-radius: 50px;
  border: ${({theme}) => `1px solid ${theme.selectCountry?.input.border}`};
  color: ${({theme}) => theme.selectCountry?.input.suggestionText};
  background: ${({theme}) => theme.selectCountry?.input.suggestionBackground};
  position: relative;
`

const CheckIcon = styled(MdCheckCircle)`
  color: #74c180;
  position: absolute;
  font-size: 25px;
  right: 15px;
`

const SearchIcon = styled(MdSearch)`
  color: ${({theme}) => theme.selectCountry?.input.suggestionText};
  position: absolute;
  font-size: 25px;
  left: 15px;
`
const StyledInput = styled(ComboboxInput)`
  outline: none;
  background: transparent;
  padding: 7px 40px;
  max-width: 200px;
  font-size: 18px;
  border: none;
  color: ${({theme}) => theme.selectCountry?.input.suggestionText};
  ::placeholder {
    color: #A3A3A3;
    font-weight: 300;
  }
`

const StyledCombobox = styled(Combobox)`
  max-width: 400px;
  margin: auto;
`

const Popover = styled(ComboboxPopover)`
  margin-top: 16px;
  text-align: left;
  border: ${({theme}) => `1px solid ${theme.selectCountry?.input.border}`};
  overflow-y: scroll;
  max-height: 170px;
  background: ${({theme}) => theme.selectCountry?.input.suggestionBackground};
`
const List = styled(ComboboxList)``
const Option = styled(ComboboxOption)`
  outline: none;
  background: transparent;
  padding: 10px 17px;
  width: 100%;
  font-size: 14px;
  border: none;
  color: ${({theme}) => theme.selectCountry?.input.suggestionText};
  [data-reach-combobox-option-text] {
    font-weight: 300;
  }
  [data-reach-combobox-option]:hover {
    font-weight: 700;
  }
  [data-user-value] {
    font-weight: 700;
  }
`

function useCountryMatch(
  term: string,
  countries: Country[],
): {matches: Country[]; countries: Country[]} {
  const matches = useMemo(
    () =>
      term.trim() === ''
        ? null
        : matchSorter(countries, term, {keys: ['name']}),
    [countries, term],
  )
  return {matches: matches ?? [], countries}
}

export {SelectCountry}
