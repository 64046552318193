import {styled} from '../context/theme-context'

export interface ContainerProps {
    background?: string
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  letter-spacing: 0;
  flex-direction: column;
  padding: 8px 16px 24px 16px;
  min-height: 100vh;
  background: ${({background}) => (background ? background : '#ffffff')};
`

export interface ComponentWrapperProps {
    padding?: string
    color?: string
    background?: string
    backgroundImage?: string
    animation?: any
}

export const ComponentWrapperBadges = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px 10vw;
  justify-content: flex-start;
  padding: 0;
  margin: 8px 0;
  width: 100%;
  position: relative;
`

export const ComponentWrapper = styled.div<ComponentWrapperProps>`
  border-radius: 10px;
  margin: 8px 0;
  width: 100%;
  position: relative;
  animation: ${props => props.animation};
  color: ${({color}) => (color ? color : '#FFFFFF')};
  background: ${({background, backgroundImage}) =>
          backgroundImage
                  ? `url('${backgroundImage}') no-repeat left top / 100%`
                  : background ? background : 'inherit'};
  background-color: ${({background}) => (background ? `${background} !important` : '#FFFFFF')};
  padding: ${({padding}) => (padding ? `${padding} !important` : '24px')};;
`

export interface TitleBarProps {
    color?: string
}

export const TitleBar = styled.div<TitleBarProps>`
  color: ${({color}) => (color ? color : '#1B1B20')};
  margin-top: 44px;
  font-size: 28px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.2px;
`

export const SubTitleBar = styled.div`
  margin-top: 15px;
  font-size: 22px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.2px;
`

export const BadgeContainer = styled.div`
  width: calc((80vw - 32px) / 3);
  min-width: 60px;
  max-width: 96px;

`

export const BadgeTitle = styled.h2`
  font-size: 14px;
  text-align: center;
  margin-top: 8px;
  line-height: 1.1;
  margin-bottom: 0;
  letter-spacing: -0.2px;
`

export const LevelText = styled.h2`
  font-size: 14px;
  text-align: center;
  margin-top: 6px;
  line-height: 1.1;
  text-transform: uppercase;
  letter-spacing: -0.2px;
`

export const ComponentWrapperAvailableAchievements = styled.div`

`


export interface LevelCardProps {
    backgroundColor?: string
}

export const LevelCard = styled.div<LevelCardProps>`
  background: ${({backgroundColor}) => (backgroundColor ? backgroundColor : '#fff')};
  padding: 16px;
  box-shadow: 0 1px 1px 0 #00000033;
  margin: 8px 0;
  border-radius: 10px;
`

export interface GraphCardProps {
}

export const GraphCard = styled.div<GraphCardProps>`
  text-align: center;
`

export const GraphTab = styled.div`
  margin-bottom: 8px;
  padding: 24px 34px;
  display: flex;
  justify-content: space-between;
`

export interface TabItemProps {
    active: boolean
}

export const TabItem = styled.div<TabItemProps>`
  font-weight: 400;
  color: ${({active}) => (active ? '#FFFFFF' : '#FFFFFF80')};
    /* text-decoration: ${({active}) => (active ? 'underline' : 'none')}; */
  border-bottom: ${({active}) => (`1px ${active ? 'solid' : 'transparent'}`)};
`


export const GraphDeed = styled.div`
  margin: 24px 0 16px 0;

  .deeds-value {
    font-size: 50px;
    font-weight: 700;
    line-height: 1;
  }

  .deeds-text {
    font-size: 26px;
  }
`

export const GraphLabel = styled.div`
  color: #88B7BB;
  text-align: right;
`

export const GraphSavings = styled.div`
  margin: 16px 0;

  .savings-value {
    font-size: 50px;
    line-height: 1;
    font-weight: 700;
  }

  .savings-text {
    font-weight: 700;
    font-size: 28px;
  }

  .savings-sub-text {
    font-size: 18px !important;
    font-weight: 400 !important;
  }
`

export interface ComparisionCardProps {
}

export const ComparisionCard = styled.div<ComparisionCardProps>`
  min-height: 130px;

  .comparison-title {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  .comparison-description {
    font-size: 15px;
  }
`

export interface ImpactsCardProps {
}

export const ImpactsCard = styled.div<ImpactsCardProps>`
  text-align: center;
  padding: 8px 0;

  .impact-points {
    font-size: 50px;
    font-weight: 700;
    line-height: 1;
  }

  .impact-title {
    font-size: 28px;
    margin-bottom: 16px;
  }
`

export const ImpactsCardZurich = styled.div`
  display: flex;
  flex-direction: column;

  .earned-text {
    text-align: end;
    margin: 0;
    font-size: 13px;
  }

  .earned-value {
    text-align: end;
    margin-top: 4px;
    font-size: 15px;
  }

  .card-history-wrapper {
    align-self: flex-end;
  }
  
  .card-value-wrapper {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .current-value-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 4px;
  }

  .current-text {
    margin: 0;
    font-size: 16px;
  }
  
  .current-value {
    margin: 0;
    font-size: 32px;
  }
  
  .right-arrow {
    margin-left: auto;
  }
`

export const Link = styled.a`
  &:hover {
    text-decoration: none;
  }
  &:visited {
    text-decoration: none;
  }
`


export interface BagdeProps {
}

export const Bagde = styled.div<BagdeProps>`
  height: 100px;
  width: 100px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
`

export interface UserProfileProps {
}

export const UserProfile = styled.div<UserProfileProps>`
  display: flex;
  justify-content: center;
  padding: 8px 0 8px 0;
  position: relative;

  .user-view {
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    border: 6px solid #F2F2F2;
    box-shadow: 0 4px 10px rgba(10, 11, 9, 0.15);
    background: white;
    position: relative;
    margin-bottom: 16px;

    img {
      height: 100%;
      width: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .user-name {
    color: #3A3A3A;
    font-size: 24px;
    font-weight: 700;
  }
`

export interface UserBriefProps {
    color?: string
    background?: string
}

export const UserBrief = styled.div<UserBriefProps>`
  margin-top: -25px;
  z-index: 1;
  padding: 2px 8px;
  text-align: center;
  border-radius: 20px;
  min-width: 6rem;
  font-weight: 400;
  font-size: 14px;
  background: ${({background}) => (background ? background : '#127076')};
  color: ${({color}) => (color ? color : '#FFFFFF')};
`
export const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center
`

export const SettingsContainer = styled.div`
  position: absolute;
  right: 0;
`