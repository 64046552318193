import React, {useState} from 'react'
import {
  enableBodyScroll,
  disableBodyScroll,
  BodyScrollOptions,
} from 'body-scroll-lock'

export const useScrollLock = (bodyScrollOption?: BodyScrollOptions) => {
  const [targetElement, setTargetElement] = useState<
    HTMLElement | Element | null
  >(null)
  React.useLayoutEffect(() => {
    if (!targetElement) {
      return
    }
    disableBodyScroll(targetElement, bodyScrollOption)
    return () => {
      enableBodyScroll(targetElement)
    }
  }, [bodyScrollOption, targetElement])

  return setTargetElement
}
