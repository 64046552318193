import {ComponentProps} from 'react'
import {Link, useLocation} from 'react-router-dom'

type KeepQueryParamLinkProps = ComponentProps<typeof Link>

const KeepQueryParamLink = ({
  to,
  children,
  ...rest
}: KeepQueryParamLinkProps) => {
  const location = useLocation()
  return (
    <Link {...rest} to={{pathname: to as string, search: location.search}}>
      {children}
    </Link>
  )
}

export {KeepQueryParamLink}
