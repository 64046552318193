import React from 'react'
import 'firebase/auth'
import {useConfig} from '../../context/config-context/config-context'
import {useQuery} from 'react-query'
import {useLocation} from 'react-router-dom'
import {useAuthClient} from '../../api/api-client'
import {useLocalization} from '../../api/localization'
import profileExampleLive from "./profile-example-062022.json"

export function useProfileView() {
    const {profileData}: any = useFetechUserProfileAndLocale()

    return {profileData}
}

export function useFetechUserProfileAndLocale() {
    const [profileData, setProfileData] = React.useState<any>({
        isLoading: true,
        data: null,
        isError: false,
        error: null,
    })
    const client = useAuthClient()
    const location = useLocation()
    const handleQuery = () => {
        return new URLSearchParams(location.search)
    }
    const language = handleQuery().get('language') || 'en'
    const config = useConfig()
    const token = config.token
    const apiKey = config.apiKey
    const {localizationData} = useLocalization({
        category: 'profile',
        language: language,
        userId: handleQuery().get('user')
    })
    const locale = handleQuery().get('locale') || window.navigator.language
    const timeZoneOffset = handleQuery().get('timeZoneOffset') || -(new Date()).getTimezoneOffset()
    const {data, isSuccess, isLoading, ...result} = useQuery<any>('profile', () => {
            let promise = client(`api/users/${handleQuery().get('user')}/profile`, {
                token: token,
                apiKey: apiKey,
                queryParams: {
                    ...(language && {language: language}),
                    locale: locale,
                    timeZoneOffset: timeZoneOffset
                }
            });

            // return profileExampleLive
            return promise
        }
    )

    React.useEffect(() => {
        if (data) {
            setProfileData({
                isLoading: false,
                isError: false,
                data: data,
                error: '',
            })
        }
    }, [data, isLoading])
    // data, isLoading -> deps

    return {profileData}
}