import {styled} from '../context/theme-context/theme-context'
import {breakpoint} from '../style/media-queries'

interface PageWrapperConfig {
  background?: string
  backgroundImage?: string | undefined
}

export const PageWrapper = styled.div<PageWrapperConfig>`
  background: ${({background, backgroundImage}) =>
    backgroundImage
      ? `url('${backgroundImage}') no-repeat center center fixed`
      : background};
  background-color: ${({background}) => background};
  background-size: cover;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;
  min-height: 100%;
  overflow-x: hidden;
`


export const Logo = styled.img`
  margin-top: 12px;
  margin-left: 60px;
  max-height: 50px;
  max-width: 100px;
  align-self: flex-start;

  ${breakpoint.sm} {
    margin-top: 23px;
    margin-left: 70px;
    max-height: 100px;
    max-width: 200px;
  }
`

export const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

interface TextWrapperConfig {
  titleColor?: string
  subtitleColor?: string
}
export const TextWrapper = styled.div<TextWrapperConfig>`
  text-align: center;

  h1 {
    color: ${p => p.titleColor};
    font-size: 26px;
    margin: 0;
    font-weight: 700;
  }
  p {
    font-weight: 400;
    color: ${p => p.subtitleColor};
    font-size: 16px;
    margin-top: 5px;
    font-weight: 400;
    margin-bottom: 15px;
  }

  ${breakpoint.sm} {
    h1 {
      font-size: 30px;
    }
    p {
      font-size: 20px;
    }
  }

  ${breakpoint.mobileLandscape} {
  }
`
