import { styled } from "../../../context/theme-context";
import React from "react";


type BadgeProps = {
    imageUrl?: string;
    allCorrect?: boolean;
};

const BadgeImage = styled.div<BadgeProps>`
    aspect-ratio: 1 / 1;
    position: relative;
    width: 100%;

`

const BadgeSvg = styled.img`
    width: 100%;
`

export const BadgeIcon = ({ imageUrl}: BadgeProps) => {

    return (
        <BadgeImage>
            <BadgeSvg src={imageUrl} alt="logo" />
        </BadgeImage>
    )
}