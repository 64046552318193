import {useAuthClient} from '../api-client'
import {useQuery} from 'react-query'
import {useConfig} from '../../context/config-context/config-context'
import {useErrorHandler} from 'react-error-boundary'
import {Country} from '../../modules/onboarding-calculator/select-country/select-country'
import {Views} from './types/onboarding-views'
import {useEffect} from 'react'
import {customPostMessage} from '../../utils/helpers'

export const useOnboardingViews = () => {
  const client = useAuthClient()
  const config = useConfig()
  const {data, isSuccess, ...result} = useQuery<{
    views: Views
    additonalData: Record<string, any>
    translations: Record<string, string>
    countries: Country[]
  }>(
    'onboarding-views',
    () =>
      client('api/dynamic-views/onboarding', {
        queryParams: {
          ...(config.language && {language: config.language}),
          ...(!config.countryCode && {countries: true}),
        },
      }),
    {
      staleTime: Infinity,
      onSuccess: () => {
        customPostMessage(
          {
            source: 'deedsterOnboarding',
            event: 'onboardingLoaded',
          },
          '*',
        )
      },
    },
  )

  useErrorHandler(result.error)

  const views = data?.views
  const translations = data?.translations
  const countries = data?.countries
  return {...result, isSuccess, views, translations, countries}
}

export const useTranslations = () => {
  const {translations, ...rest}: any = useOnboardingViews()
  return {...rest, translations}
}

export const useCountries = () => {
  const {countries, ...rest} = useOnboardingViews()
  return {...rest, countries}
}
