import {useAsync} from '../../utils/use-async'
import {Theme} from '../../types/theme/theme'
import {useEffect} from 'react'
import {client} from '../../api/api-client'
import {useErrorHandler} from 'react-error-boundary'

export function useGetTheme(themeId: string) {
  const {data: theme, isError, run} = useAsync<Theme>()
  useEffect(() => {
    if (themeId) {
      /** apiURL = '' since it fetch from public folder rather than api */
      run(
        client(`themes/${themeId || 'defaultTheme'}/theme.json`, {apiURL: ''}),
      )
    }
  }, [run, themeId])
  // useErrorHandler(isError && {message: `Theme '${themeId}' doesn't exist`})
  return {theme}
}
