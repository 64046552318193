import {useScrollLock} from '../utils/use-lock-body-scroll'
import React from 'react'
import {PageWrapper} from './page-wrapper'
import {FixedBackButton} from './fixed-back-button'
import {useNavigateAndKeepQueryParams} from '../utils/useNavigateAndKeepQueryParams'
import {styled} from '../context/theme-context'

export function Page404() {
  const navigate = useNavigateAndKeepQueryParams()
  const setTargetElement = useScrollLock()
  return (
    <PageWrapper ref={setTargetElement}>
      <FixedBackButton onClick={() => navigate('/')} />
      <TextWrapper>
        <Text>Page Not Found</Text>
      </TextWrapper>
    </PageWrapper>
  )
}
const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`
const Text = styled.h1``
