import {styled} from '../context/theme-context/theme-context'
import React from 'react'

export const IconButtonWrapper = styled.button<{
  backgroundColor: string
  size: number
}>`
  background: ${({backgroundColor}) => backgroundColor};
  border: none;
  padding: 0;
  border-radius: 50%;
  width: ${({size}) => size}px;
  height: ${({size}) => size}px;
  text-align: center;
  transition: transform 0.5s;
  will-change: transform;
  &:hover {
    transform: scale(1.05);
  }
`

export const IconButton = ({
  backgroundColor = 'transparent',
  size = 40,
  ...props
}: {
  backgroundColor?: string
  size?: number
} & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <IconButtonWrapper
      type="button"
      {...props}
      backgroundColor={backgroundColor}
      size={size}
    />
  )
}
