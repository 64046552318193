    import {useCallback} from 'react'
import {Auth, useAuth} from '../context/auth-context/auth-context'
import {useQueryClient} from 'react-query'
import {useConfig} from '../context/config-context/config-context'

interface Config {
  data?: any
  apiURL?: string
  token?: string
  apiKey?: string
  queryParams?: Record<string, any>
  headers?: any
  customConfig?: Record<string, any>
}

async function client(
  endpoint: string,
  {
    apiURL = process.env.REACT_APP_API_URL,
    data,
    token,
    apiKey,
    queryParams = {},
    headers: customHeaders,
    ...customConfig
  }: Config = {},
) {
  const config = {
    method: data ? 'POST' : 'GET',
    body: data ? JSON.stringify(data) : undefined,
    headers: {
      'X-Api-Key': apiKey ? apiKey : undefined,
      Authorization: token ? `Bearer ${token}` : undefined,
      'Content-Type': data ? 'application/json' : undefined,
      ...customHeaders,
    },
    ...customConfig,
  }

  const queryString = new URLSearchParams(queryParams).toString()
  return window
    .fetch(`${apiURL}/${endpoint}?${queryString}`, config)
    .then(async response => {
      if (response.ok) {
        if (response.status === 204) {
          return
        }

        let data
        const contentType = response.headers.get('Content-Type')
        if (contentType === 'image/svg+xml') {
          data = await response.text()
        } else {
          data = await response.json()
        }
        return data
      } else {
        return Promise.reject(response)
      }
    })
}

function useAuthClient() {
  const auth = useAuth()

  const {apiKey, useExternalUserId} = useConfig()
  return useCallback(
    (endpoint, config = {}) =>
      client(endpoint, {
        apiKey,
        ...config,
        token: config.token || (auth as Auth)?.token,
      }),
    [apiKey, auth],
  )
}

function useInvalidateQueryKey() {
  const queryClient = useQueryClient()
  return useCallback(
    async function invalidateKey(key) {
      await queryClient.invalidateQueries(key)
    },
    [queryClient],
  )
}

export {client, useAuthClient, useInvalidateQueryKey}
